import React, { useContext, useState } from "react";
import {
    Accordion, Col, Image, Row
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import EmptyCartImage from "../../assets/images/empty_cart.png";
import { store } from "../../context/store";
import AddRemoveItems1 from "../AddRemoveItems/AddRemoveItems1";
import "./OrderSummary2.css";
function OrderSummary2(props) {
    const { ProductCart, setcartdrwaer } = useContext(store);
    const GrandTotal = props.GrandTotal;
    const [decButton, setDecButton] = useState(false);
    function getExtension(filename) {
        return filename.split('.').pop()
    }
    return (
        <div className="">
            <div className='orderdetailssummery checkout-order-summery'>
                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <div className="row px-2">
                            <Accordion.Header>
                                <Col lg={6}>
                                    <span className="">{ProductCart.length}(items)</span>
                                </Col>
                                <Col lg={6} className="text-end">
                                    <span className="me-3">
                                        ₹{" "}
                                        {ProductCart.length == 0
                                            ? 0
                                            : GrandTotal}
                                    </span>
                                </Col>
                            </Accordion.Header>
                        </div>
                        <Accordion.Body>
                            {/* <CartItems /> */}
                            <>
                                {ProductCart.length != 0 ? (
                                    <div className="cart-page-container">
                                        <div className="row ">
                                            <div className="col-12">
                                                {ProductCart.map((data, index) => {
                                                    return (
                                                        <>
                                                            <Row className="my-3">
                                                                <Col lg={2} xs={5}>
                                                                    <Link
                                                                        to={`${process.env.PUBLIC_URL
                                                                            }/p/${data.fld_route?.replace(
                                                                                /\s/g,
                                                                                "-"
                                                                            ).replace('/', '')}`}
                                                                        onClick={() =>
                                                                            setcartdrwaer(false)
                                                                        }
                                                                    >
                                                                        {getExtension(data.VariantImage) === "mp4" ?
                                                                            <video src={data.VariantImage} autoPlay loop autoFocus autoCorrect='true' muted className="img-fluid w-100" />
                                                                            : <Image src={data.VariantImage} className="img-fluid w-100" />}
                                                                    </Link>
                                                                </Col>
                                                                <Col
                                                                    lg={8}
                                                                    xs={7}
                                                                    className="ps-lg-3 mt-lg-0 mt-0 ordersummarycard"
                                                                >
                                                                    <h6>
                                                                        <Link
                                                                            className="fw-bold cart-item-name"
                                                                            to={`${process.env.PUBLIC_URL
                                                                                }/p/${data.fld_route?.replace(
                                                                                    /\s/g,
                                                                                    "-"
                                                                                ).replace('/', '')}`}
                                                                            onClick={() =>
                                                                                setcartdrwaer(
                                                                                    false
                                                                                )
                                                                            }
                                                                        >
                                                                            {data.fld_itemname}-
                                                                            {data.fld_variantname}
                                                                        </Link>
                                                                    </h6>
                                                                    <h6 className="price price--end  d-lg-none">
                                                                        {parseFloat(data.Basevalue).toFixed(
                                                                            0
                                                                        )}
                                                                    </h6>
                                                                    <div className="d-flex">
                                                                        <span className="mb-1">
                                                                            {data.fld_ord_info !== "," ?

                                                                                data.fld_ord_info.split(",")
                                                                                    .length <= 0 ? (
                                                                                    <div className="variant-attributes">
                                                                                        {
                                                                                            data.fld_ord_info.split(
                                                                                                "#"
                                                                                            )[0]
                                                                                        }
                                                                                        -
                                                                                        {
                                                                                            data.fld_ord_info.split(
                                                                                                "#"
                                                                                            )[1]
                                                                                        }
                                                                                        <br />
                                                                                    </div>
                                                                                ) : (
                                                                                    data.fld_ord_info
                                                                                        .split(",")
                                                                                        .map((info) => {
                                                                                            return (
                                                                                                <div className="variant-attributes">
                                                                                                    {info.split("#")[0]} -{" "}
                                                                                                    {info.split("#")[1]}
                                                                                                    <br />
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                )

                                                                                : ""}
                                                                        </span>
                                                                    </div>
                                                                    <AddRemoveItems1 VariantId={data.fld_variantid} CartId={data.fld_cart_id} CartDetailsId={data.fld_cart_dtl_id} Quantity={data.fld_quantity} MaxQuantity={data.fld_max_qty} MinQuantity={data.fld_min_qty} />
                                                                </Col>
                                                                <Col lg={2} className="d-none d-lg-block">
                                                                    <h6 className="price price--end ms-3">
                                                                        ₹{" "}{parseFloat(data.Basevalue).toFixed(
                                                                            0
                                                                        )}
                                                                    </h6>
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row cart-page-container continueshoppingbutton emptycartimage">
                                        <div className="col-12 ml-5 text-center">
                                            <img
                                                src={EmptyCartImage}
                                                className="empty-cart-image"
                                                alt=""
                                            ></img>
                                            <h5
                                                className="fw-bold mb-3"
                                                style={{
                                                    fontSize: isMobile && "20px",
                                                }}
                                            >
                                                Your bag is empty.
                                            </h5>
                                            {/* <h6
                                                style={{
                                                    fontSize: isMobile && "16px",
                                                }}
                                            >
                                                Add Some Stuff!
                                            </h6> */}
                                            <Link
                                                to="/shop"
                                                className="change-addressButton mt-5 mb-3"
                                                onClick={() => {
                                                    if (ProductCart.length == 0) {
                                                        localStorage.removeItem("OrderDetails");
                                                    }
                                                }}
                                            >
                                                {" "}
                                                Continue Shopping
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}
export default OrderSummary2;