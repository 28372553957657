import { Skeleton } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useParams } from "react-router-dom";
import GenerateAccess from "../Api/GenerateAccess";
import PostApiCall from "../Api/postApi";
import AddToCard1 from "../components/AddToCart/AddToCart1";
import AddToCartQty from "../components/AddToCartQty/AddToCartQty";
import "./Products1.css";
import { store } from "../context/store";
import GetSeoData from "../components/GetSeo/GetSeoData";

function ProductDetailNew(props) {
  const { ProductCart, setShowCart } = useContext(store);
  const [SelectedVariant, setSelectedVariant] = useState("");
  const [VariantImage, setVariantImage] = useState("");
  const [MRP, setMRP] = useState(0.0);
  const [DiscountPrice, setDiscountPrice] = useState(0.0);
  const [ItemsInCart, setItemsInCart] = useState(0);
  const [CartLineId, setCartLineId] = useState(0);
  const [SavePer, setSavePer] = useState("");
  const [YouSave, setYouSave] = useState("");
  const [Stock, setStock] = useState("");
  const [VariantId, setVariantId] = useState("");
  const [VariantName, setVariantName] = useState("");
  const [Brandname, setBrandname] = useState("");
  const [CategoryId, setCategoryId] = useState("");
  const [Rating, setRating] = useState("");
  const [Weight, setWeight] = useState("");
  const [ProductId, setProductId] = useState("");
  const [CountryOfOrigin, setCountryOfOrigin] = useState("");
  const [ShortDescription, setShortDescription] = useState("");
  const [LongDescription, setLongDescription] = useState("");
  const [Reviews, setReviews] = useState("");
  const [MarketedBy, setMarketedBy] = useState("");
  const [DataLoading, setDataLoading] = useState("");
  const [selectedImage, setselectedImage] = useState("");
  const [SubcategoryName, setSubcategoryName] = useState("");
  const [Subcategoryid, setSubcategoryid] = useState("");
  const [CategoryName, setCategoryName] = useState("");
  const [VerticalName, setVerticalName] = useState("");
  const [ItemName, setItemName] = useState("");
  const [Verticalid, setVerticalid] = useState("");
  const [Soldby, setSoldby] = useState("");
  const [SKU, setSKU] = useState("");
  const [ShippingCharge, setShippingCharge] = useState("");
  const [AttributeDropdowndata, setAttributeDropdowndata] = useState([]);
  const [AddToBagDisable, setAddToBagDisable] = useState("");
  const [ProductDetail, setProductDetail] = useState([]);
  const [done, setDone] = useState("");
  const [AttributeDropdownCount, setAttributeDropdownCount] = useState([]);
  const [VariantAttributeValue, setVariantAttributeValue] = useState("");
  const [SelectedMainImage, setSelectedMainImage] = useState("");
  const [isInCart, setIsInCart] = useState([]);
  const params = useParams();

  const productThumbnail = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowCart(false)

  }, [params.productname])
  useEffect(() => {
    getproductDetails(params.productname);

    const InCart = ProductCart.find((item) => {
      if (item.fld_variantid == VariantId) {
        return true;
      }
      return false;
    });
    setIsInCart(InCart);
  }, [ProductCart, params.productname]);

  const getproductDetails = (props) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    PostApiCall.postRequest(
      {
        route: params.productname,
        // id: parseInt(props),
        // customerid: logindetails !== null ? logindetails[0].fld_customerid : 0,
      },
      "GetProductByVariant"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setProductDetail(obj.data);
          setDone(true);
          setSelectedVariant(obj.data[0]);
          setVariantImage(obj.data[0].VariantImage);
          setMRP(obj.data[0].fld_mrp);
          setDiscountPrice(obj.data[0].fld_selling_price);
          setSavePer(obj.data[0].fld_discount_percent);
          setYouSave(obj.data[0].YouSave);
          setStock(obj.data[0].available_stock);
          setVariantId(obj.data[0].fld_variantid);
          setVariantName(obj.data[0].fld_variantname);
          setBrandname(obj.data[0].fld_brandname);
          setCategoryName(obj.data[0].fld_categoryname);
          setCategoryId(obj.data[0].fld_categoryid);
          setRating(obj.data[0].fld_categoryname);
          setWeight(obj.data[0].weight);
          setProductId(obj.data[0].fld_productid);
          setCountryOfOrigin(obj.data[0].fld_origin_country);
          setShortDescription(obj.data[0].fld_short_description);
          setLongDescription(obj.data[0].fld_long_description);
          setMarketedBy(obj.data[0].fld_marketedby);
          setDataLoading(false);
          setselectedImage(obj.data[0].VariantImage?.split("#")[0]);
          setSubcategoryName(obj.data[0].fld_subcategoryname);
          setSubcategoryid(obj.data[0].fld_subcategoryid);
          setCategoryName(obj.data[0].fld_categoryname);
          setVerticalName(obj.data[0].fld_verticlename);
          setItemName(obj.data[0].fld_itemname);
          setVerticalid(obj.data[0].fld_verticleid);

          setSoldby(obj.data[0].soldby);
          setSKU(obj.data[0].fld_sku);
          setShippingCharge(obj.data[0].fld_shipping_amount);
          setVariantAttributeValue(obj.data[0].VariantAttributeValue);
          setItemsInCart(obj.data[0].qty_in_cart);
          setCartLineId(obj.data[0].cart_line_id);
          PostApiCall.postRequest(
            {
              categoryid: parseInt(obj.data[0].fld_categoryid),
              stateid: 0,
            },
            "GetBestSellingProduct"
          ).then((results) =>
            results.json().then((obj1) => {
              if (results.status === 200 || results.status === 201) {
                setAddToBagDisable(false);
                setDataLoading(false);
              }
            })
          );
          PostApiCall.postRequest(
            {
              variantid: parseInt(obj.data[0].fld_variantid),
            },
            "GetVariantDetailsById"
          ).then((results) =>
            results.json().then((obj1) => {
              if (results.status === 200 || results.status === 201) {
                setAttributeDropdownCount(obj1.data);
                PostApiCall.postRequest(
                  {
                    id: parseInt(obj.data[0].fld_variantid),
                    sfor: "VariantId",
                  },
                  "GetCategoryAttributeValue"
                ).then((results2) =>
                  results2.json().then((obj2) => {
                    // ;
                    if (results2.status === 200 || results2.status === 201) {
                      setAttributeDropdowndata(obj2.data);
                      setDataLoading(false);
                    }
                  })
                );
              }
            })
          );
          setAddToBagDisable(false);
        }
      })
    );


  };

  function getExtension(filename) {
    return filename?.split(".").pop();
  }

  return (<>
    <GetSeoData type="Variant" id={VariantId} route={params.productname} />
    <section className="py-lg-3 py-3 mt-4">
      <GenerateAccess />
      <Container>
        {VariantImage.length <= 0 ? (
          <Row>
            <Col lg={5}>
              <div>
                <Skeleton.Image
                  active={true}
                  className="img-fluid w-100 h-100 mb-3 item_img11 common-border-radious"
                />
              </div>
              <Row>
                <Col xs={4} lg={3}>
                  <Skeleton.Image active={true} className="img-fluid w-100" />
                </Col>
                <Col xs={4} lg={3}>
                  <Skeleton.Image active={true} className="img-fluid w-100" />
                </Col>
                <Col xs={4} lg={3}>
                  <Skeleton.Image active={true} className="img-fluid w-100" />
                </Col>
                <Col xs={4} lg={3} className="d-none d-lg-block">
                  <Skeleton.Image active={true} className="img-fluid w-100" />
                </Col>
              </Row>
            </Col>
            <Col lg={7}>
              <Row className="flex-lg-column mt-3 mt-lg-0">
                <Col lg={6}>
                  <Skeleton.Input
                    active={true}
                    className="pb-4"
                    block
                    size="small"
                  />
                </Col>
                <Col lg={5}>
                  <Skeleton.Input
                    active={true}
                    block
                    className="mb-2"
                    size="small"
                  />
                </Col>
                <Col lg={5}>
                  <Skeleton.Input
                    active={true}
                    className="pb-4"
                    block
                    size="small"
                  />
                </Col>
                <Col lg={4}>
                  <Skeleton.Input
                    active={true}
                    className="mb-2"
                    block
                    size="small"
                  />
                </Col>
                <Col lg={12}>
                  <Skeleton.Input active={true} block size="small" />
                </Col>
              </Row>
              <Row className="flex-column mt-5">
                <Col xs={6} lg={3}>
                  <Skeleton.Input
                    active={true}
                    className="mb-2"
                    block
                    size="small"
                  />
                </Col>
                <Col xs={6} lg={3}>
                  <Skeleton.Image
                    active={true}
                    className="img-fluid w-100 mb-2"
                    block
                  />
                </Col>
                <Col xs={6} lg={3}>
                  <Skeleton.Input
                    active={true}
                    className="mb-2"
                    block
                    size="small"
                  />
                </Col>
                <Col xs={6} lg={3}>
                  <Skeleton.Input
                    active={true}
                    className="mb-2"
                    block
                    size="small"
                  />
                </Col>
                <Col xs={6} lg={3}>
                  <Skeleton.Input
                    active={true}
                    className="mb-2"
                    block
                    size="small"
                  />
                </Col>
                <Col lg={4}>
                  <Skeleton.Button
                    active={true}
                    className="mb-2"
                    block
                    size="default"
                    shape="square"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={12}>
                  <Skeleton.Input
                    active={true}
                    className="mb-2"
                    block
                    size="small"
                  />
                </Col>
                <Col lg={12}>
                  <Skeleton.Input
                    active={true}
                    className="mb-2"
                    block
                    size="small"
                  />
                </Col>
                <Col lg={12}>
                  <Skeleton.Input
                    active={true}
                    className="mb-2"
                    block
                    size="small"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col lg={5} className="circle-arrow">
                {getExtension(VariantImage?.split("#")[0].toLowerCase()) ===
                  "mp4" ? (
                  <video
                    src={VariantImage.split("#")[0]}
                    autoPlay
                    loop
                    autoFocus
                    autoCorrect="true"
                    muted
                    className="w-100"
                  />
                ) : (
                  <img
                    src={
                      SelectedMainImage !== ""
                        ? SelectedMainImage
                        : VariantImage?.split("#")[0]
                    }
                    className="img-fluid w-100 item_img11 common-border-radious"
                    alt="image"></img>
                )}
                <div className="mt-3 product-small-img-craousel-arrow">
                  <MultiCarousel
                    draggable={true}
                    showDots={true}
                    responsive={productThumbnail}
                    ssr={true}
                    infinite={true}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass="carousel-container"
                    itemClass="mx-1 product-variant-wrapper">
                    {VariantImage?.split("#").map((image) => {
                      return (
                        <Col className="shawcase-thumbnail">
                          <div className="image-small item_img1 ">
                            {getExtension(image.toLowerCase()) === "mp4" ? (
                              <video
                                src={image}
                                autoPlay
                                loop
                                autoFocus
                                autoCorrect="true"
                                muted
                                className="img-fluid w-100"
                              />
                            ) : (
                              <img
                                src={image}
                                onClick={(e) => {
                                  setSelectedMainImage(image);
                                }}
                                className="img-fluid w-100 cursor-pointer common-border-radious"
                                alt=""></img>
                            )}
                          </div>
                        </Col>
                      );
                    })}
                  </MultiCarousel>
                </div>
              </Col>
              <Col lg={7} className="mt-lg-0 mt-3">
                <h5 className="mb-lg-2">
                  {ItemName} - {VariantName}
                </h5>
                <h6 className="small">
                  <Link
                    to={`/ct/${VerticalName?.replace(
                      /\s/g,
                      "-"
                    ).replace('/', '').toLowerCase()
                      }`}
                    className="text-decoration-none text-black-50">
                    {VerticalName}
                  </Link>{" "}
                  /{" "}
                  <Link
                    to={`/pct/${VerticalName?.replace(
                      /\s/g,
                      "-"
                    ).replace('/', '').toLowerCase()
                      }-${SubcategoryName?.replace(
                        /\s/g,
                        "-"
                      ).replace('/', '').toLowerCase()
                      }`}
                    className="text-decoration-none text-black-50">
                    {CategoryName}
                  </Link>{" "}
                  /{" "}
                  <Link
                    to={`/p/${VerticalName?.replace(
                      /\s/g,
                      "-"
                    ).replace('/', '').toLowerCase()
                      }-${VariantName?.replace(
                        /\s/g,
                        "-"
                      ).replace('/', '').toLowerCase()
                      }`}
                    className="text-decoration-none text-black">
                    {VariantName}
                  </Link>
                </h6>
                <h5
                  className="d-flex align-items-end gap-2 mb-lg-2"
                  style={{ marginLeft: "-5px" }}>
                  <span>
                    <BiRupee className="rupee-icon" />
                    {DiscountPrice}
                  </span>
                  <span className="text-success extra-small mb-1">
                    Inclusive of all taxes
                  </span>
                </h5>

                {DiscountPrice !== MRP ? (
                  <h5 className="d-flex align-items-center gap-3 mb-lg-2">
                    <span className="mrp-price">
                      <BiRupee className="rupee-icon" />
                      {MRP}
                    </span>
                    <span className="fw-bold text-success">{SavePer} OFF</span>
                  </h5>
                ) : (
                  ""
                )}
                <hr />
                <div>
                  <h6 className="fw-bold">Select Variant</h6>
                  {SelectedVariant.variant_list !== null ? (
                    <Row>
                      {SelectedVariant !== null && SelectedVariant !== ""
                        ? SelectedVariant.variant_list !== null
                          ? SelectedVariant.variant_list
                            .split("#")
                            .map((carddata) => (
                              <div className="variant-card-wrapper">
                                <Card className="variant-card cursor-pointer common-border-radious">
                                  <Card.Body className="p-2">
                                    <div
                                      onClick={() => {
                                        getproductDetails(
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[1]
                                        );
                                      }}>
                                      <div className=" mb-3">
                                        {getExtension(
                                          carddata
                                            .split("$")[1]
                                            .split(",")[7]
                                            .split("~")[0]
                                            .toLowerCase()
                                        ) === "mp4" ? (
                                          <video
                                            src={
                                              carddata
                                                .split("$")[1]
                                                .split(",")[7]
                                                .split("~")[0]
                                            }
                                            autoPlay
                                            loop
                                            autoFocus
                                            autoCorrect="true"
                                            muted
                                            className="img-fluid w-100"
                                          />
                                        ) : (
                                          <img
                                            src={
                                              carddata
                                                .split("$")[1]
                                                .split(",")[7]
                                                .split("~")[0]
                                            }
                                            className="img-fluid variant-thumb"
                                          />
                                        )}
                                      </div>
                                      <div className="">
                                        {carddata
                                          .split("$")[1]
                                          .split(",")[9]
                                          .split("^")
                                          .map((attribute) => (
                                            <>
                                              <span className="fw-bold">
                                                {attribute.split("%")[0]}:{" "}
                                              </span>
                                              <span className="fw-bold">
                                                {attribute.split("%")[1]}
                                              </span>{" "}
                                            </>
                                          ))}
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            ))
                          : ""
                        : ""}
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
                <hr className="my-lg-4 mt-4" />
                <Row className="justify-content-lg-start justify-content-start">
                  <Col lg={12}>
                    {ShortDescription != null ? (
                      <div
                        className="product-short-dec mb-lg-2 mb-3 product-content"
                        dangerouslySetInnerHTML={{
                          __html: ShortDescription,
                        }}></div>
                    ) : (
                      <div className="no-data">
                        <p>No product details are available.</p>
                      </div>
                    )}
                  </Col>

                  {/* {console.log(ItemsInCart)} */}
                  {Stock !== "Out Of Stock" ? (
                    <div className="product-detail-addtocard">
                      {isInCart ? (
                        <AddToCartQty
                          itemid={VariantId}
                          quantity={isInCart.fld_quantity}
                          cartlineid={isInCart.fld_cart_dtl_id}
                        />
                      ) : (
                        <Col className="pt-0" lg={3} xs={8}>
                          <AddToCard1 itemid={VariantId} />
                        </Col>
                      )}
                    </div>
                  ) : (
                    <Col lg={3} xs={12}>
                      {/* <h6 className="fw-bold out-of-stock-btn text-danger mb-0">Out of Stock</h6> */}
                      <button
                        type="button"
                        disabled
                        class="fw-bold btn w-100 out-of-stock-btn mt-3">
                        Out of Stock
                      </button>
                    </Col>
                  )}
                </Row>
                <hr className="mt-4" />
                <Row className="my-4">
                  <div className="col-12">
                    <p>
                      All our bedlinen is packed in our signature reusable cloth
                      bags. We use only AZO free colours for printing hand block
                      printed bedlinen. Please note there may be a variance of
                      2-3cms in bedlinen measurements due to hand measurements.
                    </p>
                  </div>
                </Row>
              </Col>
              <Col lg={12}>
                <Row className="border product-brand-details py-2 p-lg-4 common-border-radious mt-3 mx-0">
                  <Col lg={3} md={6} xs={6} className="mb-2">
                    <div className="">
                      <h5 className="mb-0 fw-bold">Brand </h5>
                      <p className="mb-1">{Brandname}</p>
                    </div>
                  </Col>
                  <Col lg={3} md={6} xs={6} className="mb-2">
                    <div className="">
                      <h5 className="mb-0 fw-bold">SKU </h5>{" "}
                      <p className="mb-1">{SKU}</p>
                    </div>
                  </Col>
                  <Col lg={3} md={6} xs={6} className="mb-2">
                    <div className="">
                      <h5 className="mb-0 fw-bold">Seller </h5>{" "}
                      <p className="mb-1">{Soldby}</p>
                    </div>
                  </Col>
                  <Col lg={3} md={6} xs={6} className="mb-2">
                    <div className="">
                      <h5 className="mb-0 fw-bold">Country of origin </h5>{" "}
                      <p className="mb-1">{CountryOfOrigin}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </section></>
  );
}

export default ProductDetailNew;
