import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import Logo from "../../assets/images/logo/footerlogo.png";

import GetApiCall from "../../Api/getApi";
import "./Footer.css";

function Footer() {
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    GetApiCall.getRequest("GetClientInfo").then((ClientInfo) => {
      ClientInfo.json().then((obj) => {
        setStoreName(obj.data[0].store_name);
      });
    });
  });

  return (
    <>
      <footer className="bg-footer mt-md-5 mt-4">
        <div className="px-2 pt-md-5 pt-0">
          <Container>
            <div className="row">
              <div className="col-12 col-md-3 ps-md-0 ms-md-0 mb-md-0 mb-3 px-1 d-md-block d-none">
                <div>
                  <img className="w-75 footer-img-size" src={Logo} />
                </div>
              </div>

              <div className="col-12 col-md-3 px-md-0 px-1 d-md-block d-none">
                <h3 className="fw-bolder footer-h3-heading">Information</h3>
                <ul className="list-unstyled mt-2 footer-menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/shop">Shop</a>
                  </li>

                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-3 px-md-0 px-1 d-md-block d-none">
                <h3 className="fw-bolder footer-h3-heading">Helpful Links</h3>
                <ul className="list-unstyled mt-2 footer-menu">
                  <li>
                    <a href="/frequently-asked-questions">FAQ's</a>
                  </li>
                  <li>
                    <a href="/return-policy">Return Policy</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions">Terms Of Use</a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-3 footer-social-icon px-md-0 px-1 d-md-block d-none">
                <h3 className="fw-bolder footer-h3-heading">Follow Us</h3>
                <ul className="d-flex list-unstyled mt-md-4 mt-2">
                  <li>
                    <a href="https://www.facebook.com/Aamnliving?mibextid=ZbWKwL" target="blank">
                      <FaFacebookF className="icon" />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a href="https://www.instagram.com/meluha_home/?igshid=NTdlMDg3MTY%3D" target="blank">
                      <AiFillInstagram className="icon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <Row className="copy-right-foiter d-flex mt-md-3 mt-1">
              <div className="col-12 col-md-6 text-md-start text-center py-md-3 pb-lg-3 pb-0 px-0 mx-0 copyright text-white order-lg-1 order-2">
                {/* Powered by{" "}
                <a
                  href="https://bechofy.in/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none"
                >
                  Bechofy
                </a> */}
                Powered by{" "}
                <a href="https://bechofy.in/" target="blank" className="text">
                  {" "}
                  Bechofy
                </a>{" "}
                - <br className="d-block d-md-none" /> A Product of
                <a href="https://globaltrendz.com/" target="blank" className="text">
                  &nbsp; Global Trendz
                </a>
              </div>

              <div className="col-12 col-md-6 py-md-3 pb-lg-3 pt-0 text-md-end text-center px-0 mx-0 copyright text-white footer-year-font-size  order-lg-2 order-1">
                &copy; {moment().format("YYYY")} {storeName}. All right
                reserved.
              </div>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
}

export default Footer;
