import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PostApiCall from "../../Api/postApi";
import { store } from "../../context/store";
import HeroBanner from "../HeroBanner/HeroBanner";
// skeleton
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import Card2 from "../ProductCards/Card2";
import Nodata from "../../assets/images/search-engine.png";
import GetSeoData from "../GetSeo/GetSeoData";
const GalleryStyles = styled.div`
  .gallery__grid {
    display: grid;
    ${"" /* gap: 3rem; */}
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    justify-content: center;
    padding: 0 3% 0 3%;
  }
  .gallery__title {
    font-size: 2rem;
    padding: 3%;
    text-align: center;
  }
  .item {
    margin: auto;
    border: 3px solid var(--gray-1);
  }
  .item__btns {
    display: flex;
    justify-content: space-between;
    button {
      font-size: 1.125rem;
      background-color: var(--gray-1);
      padding: 0.2rem 0.5rem;
      height: 3rem;
      border-radius: 8px;
      font-weight: bolder;
    }
  }
  .item-img {
    width: 304px;
    height: 140px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      object-fit: contain;
    }
  }
  .item-title {
    font-size: 1rem;
    height: 82px;
    text-align: center;
    margin-bottom: 1rem;
  }
  .item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .item-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    width: 60px;
  }
  .item__btnadd {
    border: 2px solid var(--red-1);
    color: var(--red-1);
  }
  .item-price {
    font-size: 2.5rem;
    color: var(--blue-1);
    text-align: center;
    margin-bottom: 1rem;
  }
  .item__btnbuy {
    border: 2px solid var(--red-1);
    background-color: var(--red-1) !important;
    color: var(--gray-1);
  }
  .item-start {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid yellow;
    svg {
      font-size: 1rem;
    }
  }
  .skeleton {
    margin-bottom: 1rem;
  }
`;
const Category = ({ location }) => {
  const params = useParams();
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { reloadSubCategory, setReloadSubCategory } = useContext(store);
  const { reloadCategory, setReloadCategory } = useContext(store);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    PostApiCall.postRequest(
      {
        whereClause: `where fld_verticle_route = '${params.verticalname}'`,
        catCount: "*",
      },
      "GetProductCategories"
    ).then((results) => {
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //
          setLoading(false);
          setCategoryData(obj.data);
        }
      });
    });
  }, [reloadCategory]);

  const rowSkeletons = 1;
  if (loading) {
    let rows = [];
    for (let index = 0; index < rowSkeletons; index++) {
      rows.push(
        <Container className="ms-5 mb-4">
          <Skeleton height={200} width={200} />
        </Container>
      );
    }

    return (
      <>
        <SkeletonTheme color="#F5F5F5" highlightColor="#ffffff">
          <GalleryStyles className="gallery__grid">
            <Skeleton height={200} />
            <div className="gallery__grid mt-4">{rows}</div>
          </GalleryStyles>
        </SkeletonTheme>
      </>
    );
  }
  return (
    <>

      <GetSeoData type={'Category'} id={null} route={params.verticalname} />
      <HeroBanner />
      <Container fluid>
        <Row className="justify-content-center">
          {categoryData.length > 0 ? (
            categoryData.map((data) => {
              return (
                <Col lg={3} className="mb-4">
                  {/* <Card2
                    product={data}
                    path={`/subcategories/${ 
                      data.fld_name == null
                        ? ""
                        : data.fld_name.replace(/\s/g, "-")
                    }/${data.fld_categoryid}`}
                  /> */}

                  {/* New section according layout */}
                  <Card2
                    product={data}
                    path={`/pct/${data.fld_route}`}
                  // path={`/products/${data.fld_name == null
                  //   ? ""
                  //   : data.fld_name.replace(/\s/g, "-")
                  //   }/${data.fld_categoryid}`}
                  />
                </Col>
              );
            })
          ) : (
            <>
              <Row className="justify-content-center align-items-center mb-5">
                <Col lg={12} className="text-center">
                  <img src={Nodata} className="no-data-img" />
                  <h4>Data not found</h4>
                </Col>
              </Row>
            </>
          )}
        </Row>

        <div className="row mt-0 mb-0 pb-0">
          {/* <div className="col-12 justify-content-center text-center">
            <a href="/shop" className="text-decoration-none">
              <button className="shop-btn mt-2 mb-3 px-5 py-2">
                <p className="m-0 p-0 product-p-shopnow">Shop Now</p>
              </button>
            </a>
          </div> */}
          <div className="col-12 text-center card-2-content">
            <p className="mb-0 pb-0">
              Premium Cotton Fabric bedlinen made in India with love !
            </p>
            <p className="pb-0 mb-0 category-p-content">
              We take great pride in creating our limited-edition design of hand
              block printed bedlinen.
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};
export default Category;
