import React, { useContext, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { store } from '../../context/store';
import Notiflix from 'notiflix';
import moment from 'moment';
import PostApiCall from '../../Api/postApi';

const GoogleLoginComponent = () => {
	const [user, setUser] = useState(null);
	const { setLoginPopupWebsite, setopenRegistration, setOpenGuestLogin, setCart, setProductCart, setcartamount, cartItemBeforeLogin, setCartItemBeforeLogin } = useContext(store);

	const handleLogin = (response) => {
		if (response?.credential) {
			console.log('Google Login Success:', response);
			const userInfo = jwtDecode(response?.credential);

			console.log('Decoded user info:', userInfo);


			Notiflix.Loading.dots();
			var Order = localStorage.getItem("OrderData");
			var Orderdetails = JSON.parse(Order);
			PostApiCall.postRequest(
				{
					name: userInfo.name,
					mobile: null,
					email: userInfo.email,
					cartid:
						localStorage?.getItem("OrderData") == null
							? null
							: Orderdetails[0].order_id,
					signupguest: "Continue",
				},
				"AddGuest"
			).then((results) =>
				// const objs = JSON.parse(result._bodyText)
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						Notiflix.Notify.success("Login Successfull");
						localStorage.setItem("CustomerData", JSON.stringify(obj.data));
						if (cartItemBeforeLogin !== null) {
							PostApiCall.postRequest(
								{
									orderdate: moment().format("lll"),
									itemdetails: `[{"Item":${cartItemBeforeLogin},"Quantity":${1}}]`,
									status: "INCART",
									customerid:
										localStorage.getItem("CustomerData") == null
											? null
											: obj.data[0].fld_customerid,
									createdon: moment().format("lll"),
									updatedon: moment().format("lll"),
									orderid:
										localStorage.getItem("OrderDetails") == null ||
											localStorage.getItem("OrderDetails") == ""
											? 0
											: obj.data[0].order_id,
									updatedby:
										localStorage.getItem("CustomerData") == null
											? null
											: obj.data[0].fld_customerid,
								},
								"AddToCartWebsite"
							).then((result) =>
								result.json().then((obj) => {
									if (result.status == 200 || result.status == 201) {
										Notiflix.Loading.remove();
										//
										if (
											localStorage.getItem("OrderDetails") == null ||
											localStorage.getItem("OrderDetails") == ""
										) {
											//
											localStorage.setItem(
												"OrderDetails",
												JSON.stringify(obj.data)
											);
											setopenRegistration(false);
											setOpenGuestLogin(false);
											setLoginPopupWebsite(false)
											getCartdetails();
											setCartItemBeforeLogin(null);
										}
										// getCartdetails1();
									} else {
										Notiflix.Notify.failure(obj.data);
									}
								})
							);
						} else {
							Notiflix.Loading.remove();
							setopenRegistration(false)
							setOpenGuestLogin(false)
							setLoginPopupWebsite(false)
							getCartdetails();

						}
						// onLogin(data);
					} else {
						Notiflix.Loading.remove();
						Notiflix.Notify.failure(obj.data);
					}
				})
			);
			setUser(userInfo);
		}
	};
	const getCartdetails = () => {
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderDetails");
		var Orderdetails = JSON.parse(Order);

		PostApiCall.postRequest(
			{
				orderid:
					localStorage.getItem("OrderDetails") == null
						? null
						: Orderdetails[0].order_id,
				id:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
			},
			"GetCartWebsite"
		)
			.then((res) => res.json())
			.then((res1) => {
				if (res1.data) {
					setProductCart(res1.data);
					setCart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
					setcartamount(res1.data[0]?.fld_cart_amount_preview);
				}
			});
	};

	const handleFailure = (error) => {
		console.error('Google Login Failed:', error);
	};

	return (
		<div>
			{!user ? (
				<GoogleLogin
					onSuccess={handleLogin}
					onError={handleFailure}
					text='continue_with'
					logo_alignment='center'
				/>

			) : (
				<>
				</>
			)}
		</div>
	);
};

export default GoogleLoginComponent;
