import React, { useContext, useEffect, useState } from "react";
// import Menu from "./Header";
// import Footer from "./Footer";
import { AES } from "crypto-js";
import Notiflix from "notiflix";
import PaymentProcessimage from "../../assets/images/paymentprocessing.png";
import PostApiCall from "../../Api/postApi";
// import Order_Success from "./assets/img/CodOrder/CodPaymentSuccessful.png";
// import 'react-calendar/dist/Calendar.css';
// import 'react-date-picker/dist/DatePicker.css';
import { Link, useParams } from "react-router-dom";
import { store } from "../../context/store";
// import Innerbanner from "../InnerBanner/InnerBanner";
import GetApiCall from "../../Api/getApi";

export default function PaymentSuccess() {
  const [shippingAddress, setShippingAddress] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [offerData, setOfferData] = useState([]);

  const [numRegex, setNumRegex] = useState(/^0|[0-9]\d*$/);
  const [mobileRegex, setMobileRegex] = useState(/^[0-9]*$/);
  const [alphaNumericRegex, setAlphaNumericRegex] = useState(/^[a-zA-Z0-9]*$/);
  const [specialRegex, setSpecialRegex] = useState(
    /[-!$%^&*()_+|~=`"{}\[\]:\/;<>?,.@#]/
  );
  const [emailRegex, setEmailRegex] = useState(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const [urlRegex, setUrlRegex] = useState(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  );

  const [txnId, setTxnId] = useState("");
  const [merKey, setMerKey] = useState("a6JOJL");
  const [merSalt, setMerSalt] = useState("cri53U9i");
  const [customerLoginData, setCustomerLoginData] = useState([]);
  const [customerHash, setCustomerHash] = useState("");
  const [mainOrder, setMainOrder] = useState([]);
  const [paymentMode, setPaymentMode] = useState("");
  const [orderId, setOrderId] = useState(null);
  const { setCart, clientData } = useContext(store);
  const { txnid } = useParams();
  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "#0f3d74",
    });

    const login = localStorage.getItem("CustomerData");
    const logindetails = JSON.parse(login);
    var cartId = 0;
    if (localStorage.getItem("OrderData") !== null) {
      cartId = Orderdetails[0].order_id;
    }

    Notiflix.Loading.circle();

    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          const mobileno = obj1.data[0].mobile;
          PostApiCall.postRequest(
            {
              whereClause: `where  fld_payment_trx_num='${txnid}'`,
            },
            "GetWebsiteOrderDetails"
          ).then((results1) =>
            results1.json().then((obj) => {
              if (results1.status == 200 || results1.status == 201) {
                PostApiCall.postRequest(
                  {
                    trxid: txnid,
                    orderid: obj.data[0]?.fld_order_id,
                  },
                  "GetCustomerOrderForm"
                ).then((results) =>
                  results.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {

                      let items = [];
                      let formattedAddress = "";
                      let customerordernumber = "";

                      //  Items List
                      items = obj1.data.map((item) => ({
                        name: item.item_name,
                        variant: item.variant_name,
                        quantity: item.fld_quantity,
                        price: item.fld_selling_price,
                        image: item.variant_image.replace(/ /g, "%20"),
                      }));

                      // For client shipping address last index
                      const lastIndex = obj1.data.length - 1;
                      const customershippingplace =
                        obj1.data[lastIndex].fld_shipping_address_all;

                      const formattedAddresstest = customershippingplace
                        .replace(/<br\s*\/?>/gi, "\n")
                        .replace(/<\/?span[^>]*>|<\/?b>/g, "")
                        .replace(/<[^>]+>/g, "");

                      formattedAddress = formattedAddresstest.replace(
                        /\n/g,
                        "<br />"
                      );
                      customerordernumber =
                        obj1.data[lastIndex].fld_orderNumber;

                      if (obj.data) {
                        try {
                          const paymentMode = obj.data[0]?.Payment_Mode;
                          const currentOrderId = obj.data[0]?.fld_order_id;
                          setOrderId(obj.data[0]?.fld_order_id);
                          setMainOrder(obj.data);
                          SendMailers(
                            obj.data[0]?.Total_Inclusive_Tax,
                            paymentMode,
                            currentOrderId,
                            mobileno,
                            items,
                            formattedAddress,
                            customerordernumber
                          ); // get the item amount and send it to the mailer
                        } catch (error) {
                          console.log(error);
                        }
                      }
                    }
                  })
                );

                Notiflix.Loading.remove();
              }
            })
          );
        }
      })
    );
  }, []);

  // send payment success mail
  const SendMailers = (
    amount,
    selectedPaymentMode,
    currentOrderId,
    mobileno,
    productsItems,
    formattedAddress,
    customerordernumber
  ) => {
    Notiflix.Loading.dots();
    var Order = localStorage.getItem("OrderData");
    var login = localStorage.getItem("CustomerData");
    var Orderdetails = JSON.parse(Order);
    var logindetails = JSON.parse(login);
    var orderid = 0;
    if (localStorage.getItem("OrderData") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    PostApiCall.postRequest(
      {
        ordernumber: txnid,
        customername: logindetails[0].fld_name,
        customeremail: logindetails[0].fld_email,
        amount: amount,
        mobile: mobileno,
        orderid: currentOrderId,
        // instagram:,
        paymentmode: selectedPaymentMode,

        // Add new details for mailer
        products: productsItems,
        shippingaddress: formattedAddress,
        customerordernumber: customerordernumber,
      },
      "CustomerOrderSuccessMailer"
    ).then((results2) =>
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          // console.log('Success')
          Notiflix.Loading.remove();
        }
      })
    );
    PostApiCall.postRequest(
      {
        cartID: orderid,
      },
      "PurgeCart"
    ).then((result) =>
      result.json().then((obj3) => {
        if (result.status == 200 || result.status == 201) {
          setCart(0);
        } else {
          Notiflix.Notify.failure("Not able to process data");
        }
      })
    );
  };

  var Order = localStorage.getItem("OrderData");
  var Orderdetails = JSON.parse(Order);
  const id = txnid;
  const secretKey = "mel#4321!";
  const encryptedID = AES.encrypt(
    JSON.stringify(id.toString()),
    secretKey
  )?.toString();
  const urlSafeBase64 = encryptedID
    .replace(/\//g, "-")
    .toString()
    .replace("?", "bechofy");

  return (
    <div>
      {/* <Menu></Menu> */}
      {/* <Innerbanner /> */}
      <div className="container mt-5">
        <div className="row blank-section align-items-center">
          <div className="col-md-12">
            <div className="payment-box text-center">
              <img
                src={PaymentProcessimage}
                className="paymentFail img-fluid"
                alt="payment success"
                height="200px"
                width="200px"
              ></img>
              {/* <div className="icon">
								<IoCheckmarkOutline />
							</div> */}
              <h1 className="mb-4">Payment Successful</h1>
              <div className="mt-3 mb-5 d-flex flex-column flex-md-row align-items-center justify-content-center pl-lg-5">
                <Link
                  to={`/view-invoice-form/${orderId}/${urlSafeBase64}`}
                  className="theme-btn text-decoration-none"
                >
                  view order
                </Link>
                <button
                  className="shop-btn border-0 mt-4 mt-md-0 ms-lg-4 py-2 px-3"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer></Footer> */}
    </div>
  );
}
