import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { FaMobileAlt } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import "react-multi-carousel/lib/styles.css";
import PostApiCall from "../../Api/postApi";
import { store } from "../../context/store";
import AddaddressForm from "./AddAddress1";
import "./SelectAddressCheckout1.css";
function SelectAddressCheckout1(props) {
  const AddressData = props.AddressData;
  const SelectedAddress = props.SelectedAddress;
  const ChangeAddressType = props.ChangeAddressType;
  const {
    Cart,
    showAddressSelectCheckout,
    setShowAddressSelectCheckout,
    showAddNewAddress,
    setShowAddNewAddress,
    billingAddress,
    selectedBillingAddress,
    setSelectedBillingAddress,
    setBillingAddress,
    selectedShippingAddress,
    setSelectedShippingAddress,
    setShippingAddress,
  } = useContext(store);
  const handleClose = () => {
    setShowAddressSelectCheckout(false)
    setShowAddNewAddress(false)
  };
  const closeAddAddress = () => {
    setShowAddNewAddress(false)
  }
  return (
    <Offcanvas
      className="address-offcanvas"
      show={showAddressSelectCheckout}
      placement="end"
      onHide={handleClose}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Select your Address</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container>

          <div
            className="row address-form mt-lg-2"
            style={{
              display: showAddNewAddress == true ? "block" : "none",
            }}
          >
            <AddaddressForm closeCanvas={closeAddAddress} />
          </div>
          {showAddNewAddress !== true &&
            <Row>
              {AddressData.map((data, i) => {
                return (
                  <>
                    <Col lg={12}>
                      <Card className="my-2">
                        <Card.Body>
                          <Form>
                            <div className="mb-3 add-check">
                              <Form.Check
                                type="checkbox"
                                id="checkbox"
                                label={data.fld_address_type}
                                checked={ChangeAddressType === "Billing" ?
                                  (selectedBillingAddress[0]?.fld_address_id ===
                                    data.fld_address_id
                                    ? true
                                    : false) : (selectedShippingAddress[0]?.fld_address_id ===
                                      data.fld_address_id
                                      ? true
                                      : false)
                                }
                                onChange={() => {
                                  const det =
                                    localStorage.getItem("CustomerData");
                                  const details = JSON.parse(det);
                                  PostApiCall.postRequest(
                                    {
                                      whereClause: `where fld_customerid=${details[0].fld_customerid} and fld_address_id=${data.fld_address_id}`,
                                    },
                                    "GetCustomerAddress"
                                  ).then((results) =>
                                    results.json().then((obj1) => {
                                      if (
                                        results.status == 200 ||
                                        results.status == 201
                                      ) {
                                        {
                                          ChangeAddressType === "Billing"
                                            ? (setSelectedBillingAddress(obj1.data))
                                            // setBillingAddress(
                                            //   data.fld_address_id
                                            // )
                                            : setSelectedShippingAddress(
                                              obj1.data
                                            );
                                          setShippingAddress(data.fld_address_id);
                                          PostApiCall.postRequest(
                                            {
                                              customerid:
                                                details[0].fld_customerid,
                                              orderid: Cart,
                                              addressid: data.fld_address_id,
                                              billingaddress: billingAddress,
                                            },
                                            "AddShippingChrg"
                                          ).then((results) =>
                                            results.json().then((obj) => {
                                              if (
                                                results.status == 200 ||
                                                results.status == 201
                                              ) {
                                                // getCartwebsite1();
                                              }
                                            })
                                          );
                                        }
                                        localStorage.setItem(
                                          "customeradd",
                                          JSON.stringify(obj1.data)
                                        );
                                      }
                                    })
                                  );
                                  handleClose();
                                }}
                              />
                            </div>
                          </Form>
                          <Col>
                            <div className="addressCard">
                              <p>
                                <b>{data.fld_contact_name}</b>
                              </p>
                              <p>
                                {data.fld_address_1},{data.fld_address_2},
                                {data.CityName}-{data.fld_pincode},
                              </p>
                              <p>
                                {data.StateName},{data.CountryName}
                              </p>
                              <p
                                style={{
                                  display:
                                    data.fld_landmark == null ? "none" : "block",
                                }}
                              >
                                <ImLocation2 /> {data.fld_landmark}
                              </p>
                              <p className="mb-0">
                                <FaMobileAlt />
                                &nbsp; {data.fld_contact_number}
                              </p>
                            </div>
                          </Col>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                );
              })}
              <div className="selectaddressbutton mt-3">
                {
                  showAddNewAddress !== true &&
                  <Button
                    className="btn btn-theme change-addressButton"
                    style={{ width: "auto" }}
                    onClick={() => {
                      setShowAddNewAddress(true);
                    }}
                  >
                    Add new Address
                  </Button>
                }
              </div>
            </Row>}
          {/* <Address /> */}

        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
export default SelectAddressCheckout1;
