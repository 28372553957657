// import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PostApiCall from "../../Api/postApi";
import orderemptyicon from "../../assets/images/search-engine.png";
// import Searchicon from "../../assets/images/dashboardimages/search.png";
import { AES } from "crypto-js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import "./Skeleton.css";
import { store } from "../../context/store";
const GalleryStyles = styled.div`
  .gallery__grid {
    display: grid;
    ${'' /* gap: 3rem; */}
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    justify-content: center;
    padding: 50px 0 0 0;
  }
  .gallery__title {
    font-size: 2rem;
    padding: 3%;
    text-align: center;
  }`;
const Chart = () => {
  const [orderData, setOrderData] = useState([]);
  const [searchInput, setsearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const { setTotalOrderData } = useContext(store)
  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "#777f80",
    });
    Notiflix.Loading.custom("");
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid}`,
      },
      "GetCustomerOrderDetails"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setOrderData(obj.data);
          setTotalOrderData(obj.data[0])
          setLoading(false);
          // //
          Notiflix.Loading.remove();
        }
      })
    );
  }, [])

  const seachBarHandler = (e) => {
    setsearchInput(...searchInput, e.target.value);
  };
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const rowSkeletons = orderData.length;
  if (loading) {
    let rows = [];
    for (let index = 0; index < rowSkeletons; index++) {
      rows.push(
        <section className='ms-4 mb-4'>
          <Skeleton height={400} />
        </section>
      );
    }

    return (
      <>
        <Row className="mt-lg-5" >
          <Col className='mb-4 gap-1'>
            <Skeleton height={230} />
          </Col>
          <Col className='mb-4 gap-1'>
            <Skeleton height={230} />
          </Col>
          <Col className='mb-4 gap-1'>
            <Skeleton height={230} />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <React.Fragment>
      <Card className="mini-stats-wid">
        <Card.Body>
          <h5 className="mb-3 fw-bold">Order History </h5>
          <Row className="justify-content-start">
            {orderData.length != 0 ? (
              orderData.map((data) => {
                const id = data.fld_payment_trx_num;
                const secretKey = 'mel#4321!';
                const encryptedID = AES.encrypt(JSON.stringify(id.toString()), secretKey).toString();
                const urlSafeBase64 = encryptedID.replace(/\//g, "-").toString().replace("?", "bechofy")
                return (

                  <>

                    <Col md="4" className="mb-3">
                      <Card>
                        <Card.Title className="d-flex customer-profile-address p-3 mb-0 justify-content-between align-items-center">
                          <div>
                            <p className="customerProfile-orderHistory">
                              order number
                            </p>
                            <p className="customer-profile-order-history">
                              {data.fld_order_number}
                            </p>
                          </div>
                          <span
                            style={{
                              position: "absolute",
                              right: "15px",
                            }}
                          >
                            <Badge className="badge">
                              {data.fld_order_status}
                            </Badge>{" "}
                          </span>
                        </Card.Title>
                        <hr className="my-0" />
                        <Card.Body>
                          <div className="">
                            <div className="col-12 my-2">
                              <p className="customerProfile-orderHistory">
                                total amount
                              </p>
                              <p className="customer-profile-order-history">
                                ₹ {data.Total_Inclusive_Tax}
                              </p>
                            </div>
                            {/* <div className="col-12">
                                <p className="customerProfile-orderHistory">
                                  items
                                </p>
                                <p className="customer-profile-order-history">
                                  {data.total_order}Bloom,1 x Burgen Bloosm
                                </p>
                              </div> */}
                            <div className="row my-2">
                              <div className="col-6">
                                <p className="customerProfile-orderHistory">
                                  ordered on
                                </p>
                                <p className="customer-profile-order-history">
                                  {data.fld_order_date}
                                </p>
                              </div>
                              <div className="col-6 text-end">
                                <Link to={`/view-invoice-form/${urlSafeBase64}`} className="btn btn-theme btn-sm" style={{ width: 'auto' }}>View</Link>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                  </>
                )
              })

            ) : (
              <Row>
                <Col sm="12" className="text-center">
                  <img
                    src={orderemptyicon}
                    alt="block user"
                    className="customerhistory-icons ms-3 mt-1"
                    style={{ marginRight: "5px" }}
                  />
                </Col>
                <Col sm="12" className="text-center my-3">
                  <h6>No Orders Placed</h6>
                </Col>
              </Row>
            )}
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
export default Chart;
