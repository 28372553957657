import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Offcanvas, Row } from "react-bootstrap";
// import { CiCircleRemove } from "react-icons/ci";
import "react-multi-carousel/lib/styles.css";
import GetApiCall from "../../Api/getApi";
import PostApiCall from "../../Api/postApi";
import AddaddressForm from "../../components/Address/AddAddress1";
import { store } from "../../context/store";
import "./checkout.css";
// skeleton
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import SelectAddressCheckout1 from "../../components/Address/SelectAddressCheckout1";
import ApplyCouponCheckout1 from "../../components/ApplyCouponCheckout/ApplyCouponCheckout1";
import BillDetailsCheckout1 from "../../components/BillDetailsCheckout/BillDetailsCheckout1";
import CheckoutAddress1 from "../../components/CheckoutAddress/CheckoutAddress1";
import OrderSummary2 from "../../components/OrderSummary/OrderSummary2";
import "../Skeleton.css";
import axios from "axios";
import ManageCustomerAPI from "../../pages/Checkout/ManageCustomerAPI";
import GetSeoData from "../../components/GetSeo/GetSeoData";
const GalleryStyles = styled.div`
  .gallery__grid {
    display: grid;
    ${"" /* gap: 3rem; */}
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    justify-content: center;
    padding: 50px 0 0 0;
  }
  .gallery__title {
    font-size: 2rem;
    padding: 3%;
    text-align: center;
  }
  .item {
    margin: auto;
    border: 3px solid var(--gray-1);
  }
  .item__btns {
    display: flex;
    justify-content: space-between;
    button {
      font-size: 1.125rem;
      background-color: var(--gray-1);
      padding: 0.2rem 0.5rem;
      height: 3rem;
      border-radius: 8px;
      font-weight: bolder;
    }
  }
  .item-img {
    width: 304px;
    height: 140px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      object-fit: contain;
    }
  }
  .item-title {
    font-size: 1rem;
    height: 82px;
    text-align: center;
    margin-bottom: 1rem;
  }
  .item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .item-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    width: 60px;
  }
  .item__btnadd {
    border: 2px solid var(--red-1);
    color: var(--red-1);
  }
  .item-price {
    font-size: 2.5rem;
    color: var(--blue-1);
    text-align: center;
    margin-bottom: 1rem;
  }
  .item__btnbuy {
    border: 2px solid var(--red-1);
    background-color: var(--red-1) !important;
    color: var(--gray-1);
  }
  .item-start {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid yellow;
    svg {
      font-size: 1rem;
    }
  }
  .skeleton {
    margin-bottom: 1rem;
  }
`;

const Checkout = () => {
  const [cartData, setCartData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [check, setCheck] = useState(false);
  const [onlinePayment, setOnlinePayment] = useState("");
  // const [shippingAddress, setShippingAddress] = useState(null);
  // const [billingAddress, setBillingAddress] = useState(null);
  const [cod, setCod] = useState("No");
  const [offerData, setOfferData] = useState([]);
  const [couponCode, setCouponCode] = useState(null);
  const [cartID, setCartID] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [offerErrorCode, setOfferErrorCode] = useState("");
  const [offerErrorMessage, setOfferErrorMessage] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [showCouponMoreInformation, setShowCouponMoreInformation] =
    useState(false);
  const [enteredCouponCode, setEnteredCouponCode] = useState(null);
  const [couponErrorCodeTextBox, setCouponErrorCodeTextBox] = useState(null);
  const [offerErrorMessageTextBox, setOfferErrorMessageTextBox] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [offerDiscountAmount, setOfferDiscountAmount] = useState(0);
  const [couponDiscountAmount, setCouponDiscountAmount] = useState(0);
  const [shippingCharges, setShippingCharges] = useState(0);
  const [GST, setGST] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [couponDataId, setCouponDataId] = useState(null);
  const [addressCard, setAddressCard] = useState(false);
  const [title, setTitle] = useState("Home");
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [area, setArea] = useState(null);
  const [flatNo, setFlatNo] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [numRegex, setNumRegex] = useState(/^[0-9]*$/);
  const [GSTNumber, setGSTNumber] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState([]);
  // const [selectedBillingAddress, setSelectedBillingAddress] = useState([]);
  // const [selectedShippingAddress, setSelectedShippingAddress] = useState([]);
  const [selectedAllAddress, setSelectedAllAddress] = useState([]);
  const [loadingAplyCoupon, setLoadingAplyCoupon] = useState(false);
  const [GSTonShipping, setGSTonShipping] = useState(null);
  const [loading, setLoading] = useState(true);
  const [changeAddress, setchangeAddress] = useState(false);
  // const [changeAddressType, setchangeAddressType] = useState("");
  const [addAddress, setaddAddress] = useState(false);
  const [decButton, setdecButton] = useState(false);
  const { Cart, setCart, showAddressSelectCheckout, ProductCart } = useContext(store);
  const {
    setShowAddressSelectCheckout,
    changeAddressType,
    shippingAddress,
    showAddNewAddress,
    setShowAddNewAddress,
    selectedShippingAddress,
    selectedBillingAddress,
    billingAddress,
    setSelectedBillingAddress,
    setBillingAddress,
    setSelectedShippingAddress,
    setShippingAddress,
  } = useContext(store);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  // For Payment
  // const [cartId, setCartId] = useState("");
  const [txnId, setTxnId] = useState([]);
  const [clientPhoneNumber, setClientPhoneNumber] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientLogo, setClientLogo] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState(null);
  const [clientGST, setClientGST] = useState(null);
  const [clientWesite, setClientWesite] = useState(null);

  const handleCoupon = () => {
    setIsCouponApplied(!isCouponApplied);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });
    localStorage.removeItem("currentPage");
    localStorage.removeItem("SelectAddress");
    // const store = this.context;
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    GetApiCall.getRequest("GetOfferList").then((resultdes) =>
      resultdes.json().then((obj) => {
        //
        if (resultdes.status == 200 || resultdes.status == 201) {
          setOfferData(
            obj.data.filter((value) => value.fld_showonwebsite == "Active")
          );
          setLoading(false);
          // this.setState({
          //   OfferData: obj.data.filter(
          //     (value) => value.fld_showonwebsite == "Active"
          //   ),
          //   Loading: false,
          // });
        }
      })
    );

    // PostApiCall.postRequest(
    //   {
    //     whereClause: `where fld_customerid=${logindetails[0].fld_customerid}`,
    //   },
    //   "GetCustomerAddress"
    // ).then((results) =>
    //   results.json().then((obj) => {
    //     if (results.status == 200 || results.status == 201) {
    //       setAddressData(obj.data);
    //       setLoading(false);
    //       // this.setState({
    //       //   AddressData: obj.data,
    //       //   Loading: false,
    //       // });
    //       localStorage.setItem("customeradd", JSON.stringify(obj.data));
    //     }
    //   })
    // );
    getcartWebsiteApi();
  }, []);
  useEffect(() => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${logindetails[0].fld_customerid}`,
      },
      "GetCustomerAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAddressData(obj.data);
          setLoading(false);
          // this.setState({
          //   AddressData: obj.data,
          //   Loading: false,
          // });
          localStorage.setItem("customeradd", JSON.stringify(obj.data));
        }
      })
    );

  }, [showAddNewAddress])

  // const getCartdetails = () => {

  //   getcartWebsiteApi();
  // }
  useEffect(() => {
    getCartwebsite();
    ManageCustomerAPI.postRequest(
      {
        whereClause: `where fld_company_id = ${12}`,
      },
      "API/GetClientData"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          setClientAddress(obj.data[0].fld_address);
          setClientEmail(obj.data[0].fld_email);
          setClientGST(obj.data[0].fld_gst);
          setClientWesite(obj.data[0].fld_website);
          setClientPhoneNumber(obj.data[0].fld_mobilenumber);
          setClientName(obj.data[0].fld_companyname);
          setClientLogo(obj.data[0].fld_storelogo);
        }
      })
    );
  }, [isCouponApplied, ProductCart]);
  const handlePayment = async (amount) => {
    const login = localStorage.getItem("CustomerData");
    const logindetails = JSON.parse(login);
    try {
      const response = await axios.post(
        "https://api.bechofy.in/melhua-api/payment",
        // "http://localhost:8090/melhua-api/payment",
        { amount }
      );
      const { order } = response.data;
      const options = {
        key: process.env.REACT_PAYMENT_KEY || "rzp_live_yIQBHQypL1zuct",
        // key: process.env.REACT_PAYMENT_KEY || "rzp_test_TRjxLlgylxDQAr",
        amount,
        currency: "INR",
        name: process.env.REACT_NAME || "MeluhaHome",
        description: "MeluhaHome Products",
        image: clientLogo,
        order_id: order.id,
        handler: async (response) => {
          const body = { ...response };
          Notiflix.Loading.dots("Your transaction is under process..");
          try {
            const validateRes = await axios.post(
              // "http://localhost:8090/melhua-api/RazorPayPaymentStatus",
              "https://api.bechofy.in/melhua-api/RazorPayPaymentStatus",
              body,
              { headers: { "Content-Type": "application/json" } }
            );
            const jsonRes = validateRes.data;

            const finalRes = await axios.post(
              // "http://localhost:8090/melhua-api/RazorPayResponse",
              "https://api.bechofy.in/melhua-api/RazorPayResponse",
              {
                cartid: cartID,
                customerid:
                  localStorage.getItem("CustomerData") == null
                    ? null
                    : logindetails[0].fld_customerid,
                amount: grandTotal,
                productinfo: "",
                firstname:
                  localStorage.getItem("CustomerData") == null
                    ? null
                    : logindetails[0].fld_name,
                email:
                  localStorage.getItem("CustomerData") == null
                    ? null
                    : logindetails[0].fld_email,
                mihpayid: jsonRes.status.id,
                requestid: jsonRes.status.order_id,
                txnamount: jsonRes.status.amount,
                errorcode: jsonRes.status.error_code,
                paymentsource: jsonRes.status.method,
                errormsg: jsonRes.status.error_description,
                shippingaddress: shippingAddress,
                billingaddress: billingAddress,
                status: jsonRes.status.status,
              }
            );

            if (finalRes.data.message === true) {
              // window.location.href = `/paymentprocess/${txnId}`;
              window.location.href = `/paymentsuccess/${txnId}`;
              // history.push(`/paymentsuccess/${paymentTrxNum}`);
              Notiflix.Loading.remove();
            } else {
              console.log("Payment error");
              Notiflix.Loading.remove();
            }
          } catch (error) {
            console.error("Error:", error);
            Notiflix.Loading.remove();
          }

          // fetch(
          //   "http://localhost:8090/melhua-api/RazorPayPaymentStatus",
          //   {
          //     method: "POST",
          //     body: JSON.stringify(body),
          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //   }
          // )
          //   .then((validateRes) => validateRes.json())

          //   .then((obj) => {
          //     if (obj.status.status === "captured") {
          //       window.location.href = `/paymentprocess/${txnId}`;
          //     } else {
          //     }
          //   })
          //   .catch((error) => {
          //     console.error("Error fetching payment status:", error);
          //   });
        },

        prefill: {
          name: logindetails ? logindetails[0].fld_name : null,
          email: logindetails ? logindetails[0].fld_email : null,
        },
        notes: {
          itemsdetaiils: JSON.stringify(cartItemsDetail),
          cartid: cartID,
          paymentTrxNum: txnId,
        },
        theme: {
          color: "#0f3d74",
        },
      };
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        const rzp = new window.Razorpay(options);
        rzp.open();
      };
      document.head.appendChild(script);
    } catch (error) {
      console.error(error);
    }
  };

  const getCartwebsite = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderDetails") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    ).then((result) => {
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setCart(obj.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          // this.context.setCart(
          //   obj.data.reduce((a, v) => (a = a + v.fld_quantity), 0)
          // );
          setCartID(obj.data[0]?.fld_cart_id);
          setCouponId(obj.data[0]?.fld_offerid);
          setCouponDataId(obj.data[0]?.fld_coupon_id);
          setCouponCode(obj.data[0]?.fld_code);
          setSubtotal(obj.data[0]?.fld_total_net_amount);
          setOfferDiscountAmount(obj.data[0]?.fld_cart_discount_amount);
          setCouponDiscountAmount(
            obj.data[0]?.fld_coupon_discount == null ||
              obj.data[0]?.fld_coupon_discount == 0
              ? 0.0
              : obj.data[0]?.fld_coupon_discount
          );
          setShippingCharges(
            obj.data[0]?.fld_shipping_charge == null
              ? 0
              : obj.data[0]?.fld_shipping_charge
          );
          setGST(obj.data[0]?.fld_total_gst);
          setGrandTotal(obj.data[0]?.fld_cart_amount);
          setGSTonShipping(
            obj.data[0]?.fld_shipping_gst == null
              ? 0.0
              : obj.data[0]?.fld_shipping_gst
          );
          setShippingAddress(obj.data[0]?.fld_address_id);
          setBillingAddress(obj.data[0]?.fld_billing_address);
          setTxnId(obj.data[0]?.fld_payment_trx_num);
          setLoading(false);
          var whereBilling = "";
          var whereShipping = "";
          if (
            obj.data[0]?.fld_billing_address != null &&
            obj.data[0]?.fld_billing_address !== ""
          ) {
            whereBilling = `where fld_customerid=${logindetails[0]?.fld_customerid} and fld_address_id = ${obj.data[0]?.fld_billing_address}`;
          } else {
            whereBilling = `where fld_primary_flag = 'Yes' and fld_customerid=${logindetails[0]?.fld_customerid}`;
          }
          if (
            obj.data[0]?.fld_address_id != null &&
            obj.data[0]?.fld_address_id !== ""
          ) {
            whereShipping = `where fld_customerid=${logindetails[0].fld_customerid} and fld_address_id = ${obj.data[0]?.fld_address_id}`;
          } else {
            whereShipping = `where fld_primary_flag = 'Yes' and fld_customerid=${logindetails[0].fld_customerid} `;
          }
          PostApiCall.postRequest(
            {
              whereClause: whereBilling,
            },
            "GetCustomerAddress"
          ).then((results) =>
            results.json().then((obj1) => {
              if (results.status == 200 || results.status == 201) {
                if (obj1.data.length > 0) {
                  setSelectedBillingAddress(obj1.data);
                  if (billingAddress === null) {
                    setBillingAddress(obj1.data[0].fld_address_id);
                  }

                  setLoading(false);
                  // this.setState({
                  //   SelectedBillingAddress: obj1.data,
                  //   Loading: false,
                  // });
                  if (
                    obj.data[0]?.fld_billing_address === null &&
                    obj.data[0]?.fld_billing_address === ""
                  ) {
                    PostApiCall.postRequest(
                      {
                        customerid:
                          localStorage.getItem("CustomerData") == null
                            ? null
                            : logindetails[0].fld_customerid,
                        orderid: cartID,
                        billingaddress: obj.data[0]?.fld_address_id,
                      },
                      "AddShippingChrg"
                    ).then((results) =>
                      results.json().then((obj2) => {
                        if (results.status == 200 || results.status == 201) {
                          getCartwebsite();
                        }
                      })
                    );
                    localStorage.setItem(
                      "customeradd",
                      JSON.stringify(obj.data)
                    );
                  }
                }
              }
            })
          );
          PostApiCall.postRequest(
            {
              whereClause: whereShipping,
            },
            "GetCustomerAddress"
          ).then((results) =>
            results.json().then((obj1) => {
              if (results.status == 200 || results.status == 201) {
                setSelectedShippingAddress(obj1.data);
                if (shippingAddress === null || obj1.data.length > 0) {
                  setShippingAddress(obj1.data[0]?.fld_address_id);
                }

                setLoading(false);
                // this.setState({
                //   SelectedShippingAddress: obj1.data,
                //   Loading: false,
                // });
                if (
                  obj.data[0]?.fld_address_id === null &&
                  obj.data[0]?.fld_address_id === ""
                ) {
                  PostApiCall.postRequest(
                    {
                      customerid:
                        localStorage.getItem("CustomerData") == null
                          ? null
                          : logindetails[0].fld_customerid,
                      orderid: cartID,
                      addressid: obj.data[0]?.fld_address_id,
                    },
                    "AddShippingChrg"
                  ).then((results) =>
                    results.json().then((obj2) => {
                      if (results.status == 200 || results.status == 201) {
                        getCartwebsite();
                      }
                    })
                  );
                  localStorage.setItem("customeradd", JSON.stringify(obj.data));
                }
              }
            })
          );
        }
      });
    });
  };

  const getcartWebsiteApi = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderDetails") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    ).then((result) => {
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setCart(obj.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          // this.context.setCart(
          //   obj.data.reduce((a, v) => (a = a + v.fld_quantity), 0)
          // );
          setCartData(obj.data);
          // this.setState({
          //   cartData: obj.data,
          // })
        }
      });
    });
  };
  if (ProductCart?.length) {
    var cartItemsDetail = ProductCart?.map((item) => ({
      name: item.fld_variantname,
      price: item.fld_selling_price,
      quantity: item.fld_quantity,
    }));
  }

  const handleClose = () => {
    setShowAddNewAddress(false);
    setShowAddressSelectCheckout(true)
  };

  const rowSkeletons = 3;
  if (loading) {
    let rows = [];
    for (let index = 0; index < rowSkeletons; index++) {
      rows.push(
        <section className="ms-4 mb-4">
          <Skeleton height={400} />
        </section>
      );
    }



    return (
      <>
        <GetSeoData type="Cart" id={null} />
        <SkeletonTheme color="#F5F5F5" highlightColor="#ffffff">
          <GalleryStyles className="gallery__grid">
            <Container fluid>
              <Row className="mt-5">
                <Col lg={4}>
                  <Skeleton height={250} />
                </Col>
                <Col lg={4}>
                  <Skeleton height={250} />
                </Col>
                <Col lg={4}>
                  <Skeleton height={150} />
                  <Skeleton height={100} />
                </Col>
              </Row>
              {/* <div className="gallery__grid">{rows}</div> */}
              <Row className="mt-lg-5">
                <Col lg={8} className="mb-4 gap-1">
                  <Skeleton height={550} />
                </Col>
                <Col className="mb-4 gap-1">
                  <Skeleton height={230} />
                  <Skeleton height={50} />
                </Col>
              </Row>
            </Container>
          </GalleryStyles>
        </SkeletonTheme>
      </>
    );
  }
  return (
    <>
      <Container fluid className="mt-4 ">
        <h1 className="main-title text-capitalize mb-5 text-center mt-4">
          Checkout
        </h1>

        <Row>
          <Col lg={8}>
            <Row>
              {/* billing-address */}
              {/* shipping-address */}
              <Col lg={6} className="checkout-page-address">
                <CheckoutAddress1
                  AddressType="Shipping"
                  SelectedAddress={selectedShippingAddress}
                />
              </Col>
              <Col lg={6} className="checkout-page-address">
                <CheckoutAddress1
                  AddressType="Billing"
                  SelectedAddress={selectedBillingAddress}
                />
              </Col>
            </Row>
            {/* order-summary */}
            <div className="shippingaddresscard">
              <Col lg={12} className="Shipping-Address p-3 pt-2 my-4">
                <span className="heading-font" style={{ lineHeight: "35px" }}>
                  Order Summary
                </span>
                <OrderSummary2 GrandTotal={grandTotal} />
              </Col>
            </div>
          </Col>
          <Col lg={4}>
            {/* apply-coupon */}
            <ApplyCouponCheckout1
              OfferData={offerData}
              cartID={cartID}
              handleCoupon={handleCoupon}
              CouponDiscountAmount={couponDiscountAmount}
              CouponId={couponId}
            />
            {/* bill-details */}
            <BillDetailsCheckout1
              handleCoupon={handleCoupon}
              SubTotal={subtotal}
              CouponDiscountAmount={couponDiscountAmount}
              CouponCode={couponCode}
              OfferDiscountAmount={offerDiscountAmount}
              CouponId={couponId}
              GST={GST}
              ShippingCharges={shippingCharges}
              GSTonShipping={GSTonShipping}
              GrandTotal={grandTotal}
              CartId={cartID}
            />
            {/* payment-method */}
            <div className="orderdeatils-payment">
              <div className="oderDetails p-3 mt-4 py-2">
                <Row className="line-height">
                  <div className="border-bottom">
                    <span className="heading-font">Payment Method</span>
                  </div>
                </Row>
                <Row className="mx-0 my-2">
                  <Col lg={12} className="px-0 py-1">
                    <Form>
                      <div>
                        <Form.Check
                          type="radio"
                          id="radio"
                          className="payment-checkbox"
                          label="Credit Card / Debit card / Net Banking"
                          checked={true}
                          onChange={() => {
                            setCod("No");
                            // this.setState({
                            //   COD: "No",
                            // });
                          }}
                        />
                      </div>
                    </Form>
                  </Col>
                  <Col lg={12} className="px-0 py-1">
                    {/* <Form>
                      <div>
                        <Form.Check
                          type="radio"
                          id="radio"
                          label="Cash On Delivery"
                          checked={this.state.COD == "yes" ? true : false}
                          onChange={() => {
                            this.setState({
                              COD: "yes",
                            });
                          }}
                        />
                      </div>
                    </Form> */}
                  </Col>
                </Row>
              </div>
            </div>
            {/* pay-now button */}
            <div className="my-3">
              {/* {
                console.log(billingAddress)
              } */}
              {ProductCart.length > 0 && <Button
                className="btn btn-theme shop-btn btn-lg w-100"
                onClick={() => {
                  if (selectedShippingAddress.length != 0) {
                    if (selectedBillingAddress.length != 0) {
                      const det = localStorage.getItem("CustomerData");
                      const details = JSON.parse(det);
                      PostApiCall.postRequest(
                        {
                          cartid: cartID,
                          billingaddress: selectedBillingAddress[0].fld_address_id,
                          shippingaddress: selectedShippingAddress[0].fld_address_id,
                        },
                        "UpdateCartAddAddress"
                      ).then((results) =>
                        results.json().then((obj) => {
                          if (results.status == 200 || results.status == 201) {
                            localStorage.getItem(
                              "OrderDetails",
                              JSON.stringify(obj.data)
                            );
                            handlePayment(grandTotal)
                            // handlePayment(1)
                            // window.location.href = "/checkoutsummary";
                          }
                        })
                      );
                    } else {
                      Notiflix.Notify.failure("Please Select Billing Address");
                    }
                  } else {
                    Notiflix.Notify.failure("Please Select Shipping Address");
                  }
                }}
              >
                { }
                Pay Now
              </Button>}
            </div>
          </Col>
        </Row>
      </Container>
      <SelectAddressCheckout1
        AddressData={addressData}
        SelectedAddress={selectedAddress}
        ChangeAddressType={changeAddressType}
      />

      {/* <Offcanvas
        className="address-offcanvas"
        show={showAddNewAddress}
        placement="end"
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add New Address</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddaddressForm closeCanvas={handleClose} />
        </Offcanvas.Body>
      </Offcanvas> */}
      {/* <Address/> */}
    </>
  );
};
export default Checkout;
