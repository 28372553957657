import React, { useContext } from "react";
import { Card, Col } from "react-bootstrap";
import { FaMobileAlt } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { MdAddLocationAlt, MdEditLocationAlt } from "react-icons/md";
import "react-multi-carousel/lib/styles.css";
import { store } from "../../context/store";
import "./CheckoutAddress1.css";
function CheckoutAddress1(props) {
  const AddressType = props.AddressType;
  const {
    setChangeAddressType,
    setChangeAddress,
    setAddressCard,
    setShowAddressSelectCheckout,
  } = useContext(store);
  const SelectedAddress = props.SelectedAddress;
  return (
    <div className="shipping-address-card">
      <div className="Shipping-Address p-3 pt-2 mb-lg-0 mb-4">
        <Col lg={12} className="border-bottom d-flex justify-content-between">
          <span className="heading-font" style={{ lineHeight: "35px" }}>
            {AddressType}
          </span>
          <div
            className="fs-4"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowAddressSelectCheckout(true);
              setChangeAddressType(AddressType);
            }}>
            <MdEditLocationAlt />
          </div>
        </Col>
        {SelectedAddress.length == 0 ? (
          <>
            {" "}
            <div className="my-5">
              <div className="text-center">
                <h6 className="fw-bold">Your {AddressType} is empty.</h6>
                <p className="small m-0">
                  Add {AddressType} for easy checkout.
                </p>
              </div>
              <div className="text-center add-address-button">
                <button
                  className="mt-4 btn shop-btn px-4 "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowAddressSelectCheckout(true);
                    setChangeAddressType(AddressType);
                  }}>
                  <div className="">
                    <MdAddLocationAlt />
                  </div>
                  <div>Add address</div>
                </button>
              </div>
            </div>
          </>
        ) : (
          SelectedAddress.map((data, i) => {
            return (
              <>
                <Card className="mt-4">
                  <Card.Body>
                    <div className="addressCard">
                      <div className="d-flex justify-content-between align-items-start">
                        <p>
                          <b>{data.fld_contact_name}</b>
                        </p>
                        <p className="shop-btn px-2">{data.fld_address_type}</p>
                      </div>
                      <p>
                        {data.fld_address_1},{data.fld_address_2},
                        {data.CityName}-{data.fld_pincode},
                      </p>
                      <p>
                        {data.StateName},{data.CountryName}
                      </p>
                      <p
                        style={{
                          display: data.fld_landmark == null ? "none" : "block",
                        }}>
                        <ImLocation2 /> {data.fld_landmark}
                      </p>
                      <p style={{ marginBottom: "0" }}>
                        <FaMobileAlt />
                        &nbsp; {data.fld_contact_number}
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </>
            );
          })
        )}
      </div>
    </div>
  );
}
export default CheckoutAddress1;
