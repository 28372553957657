import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useParams } from "react-router-dom";
import GenerateAccess from "../Api/GenerateAccess";
import PostApiCall from "../Api/postApi";
import AddToCard1 from "../components/AddToCart/AddToCart1";
import AddToCartQty from "../components/AddToCartQty/AddToCartQty";
import "./Products.css";

function ProductDetail(props) {
  const product = props.product;
  const [SelectedVariant, setSelectedVariant] = useState("");
  const [VariantImage, setVariantImage] = useState("");
  const [MRP, setMRP] = useState(0.0);
  const [DiscountPrice, setDiscountPrice] = useState(0.0);
  const [ItemsInCart, setItemsInCart] = useState(0);
  const [CartLineId, setCartLineId] = useState(0);
  const [SavePer, setSavePer] = useState("");
  const [YouSave, setYouSave] = useState("");
  const [Stock, setStock] = useState("");
  const [VariantId, setVariantId] = useState("");
  const [VariantName, setVariantName] = useState("");
  const [Brandname, setBrandname] = useState("");
  const [CategoryId, setCategoryId] = useState("");
  const [Rating, setRating] = useState("");
  const [Weight, setWeight] = useState("");
  const [ProductId, setProductId] = useState("");
  const [CountryOfOrigin, setCountryOfOrigin] = useState("");
  const [ShortDescription, setShortDescription] = useState("");
  const [LongDescription, setLongDescription] = useState("");
  const [Reviews, setReviews] = useState("");
  const [MarketedBy, setMarketedBy] = useState("");
  const [DataLoading, setDataLoading] = useState("");
  const [selectedImage, setselectedImage] = useState("");
  const [SubcategoryName, setSubcategoryName] = useState("");
  const [Subcategoryid, setSubcategoryid] = useState("");
  const [CategoryName, setCategoryName] = useState("");
  const [VerticalName, setVerticalName] = useState("");
  const [ItemName, setItemName] = useState("");
  const [Verticalid, setVerticalid] = useState("");
  const [Soldby, setSoldby] = useState("");
  const [SKU, setSKU] = useState("");
  const [ShippingCharge, setShippingCharge] = useState("");
  const [AttributeDropdowndata, setAttributeDropdowndata] = useState([]);
  const [AddToBagDisable, setAddToBagDisable] = useState("");
  const [ProductDetail, setProductDetail] = useState([]);
  const [done, setDone] = useState("");
  const [AttributeDropdownCount, setAttributeDropdownCount] = useState([]);
  const [VariantAttributeValue, setVariantAttributeValue] = useState("");
  const [SelectedMainImage, setSelectedMainImage] = useState("");
  const params = useParams();
  const productThumbnail = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };
  const productVariant = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };
  const smallImage = {
    src: String,
    srcSet: String,
    sizes: String,
    width: Number,
    height: Number,
    isFluidWidth: Boolean,
    alt: String,
    onLoad: Function,
    onError: Function,
  };
  useEffect(() => {
    getproductDetails(params.variantid);
  }, []);

  const getproductDetails = (props) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    PostApiCall.postRequest(
      {
        id: parseInt(props),
        customerid: logindetails !== null ? logindetails[0].fld_customerid : 0,
      },
      "GetProductByVariant"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          ////

          setProductDetail(obj.data);
          setDone(true);

          setSelectedVariant(obj.data[0]);
          setVariantImage(obj.data[0].VariantImage);
          setMRP(obj.data[0].fld_mrp);
          setDiscountPrice(obj.data[0].fld_selling_price);
          setSavePer(obj.data[0].fld_discount_percent);
          setYouSave(obj.data[0].YouSave);
          setStock(obj.data[0].available_stock);
          setVariantId(obj.data[0].fld_variantid);
          setVariantName(obj.data[0].fld_variantname);
          setBrandname(obj.data[0].fld_brandname);
          setCategoryName(obj.data[0].fld_categoryname);
          setCategoryId(obj.data[0].fld_categoryid);
          setRating(obj.data[0].fld_categoryname);
          setWeight(obj.data[0].weight);
          setProductId(obj.data[0].fld_productid);
          setCountryOfOrigin(obj.data[0].fld_origin_country);
          setShortDescription(obj.data[0].fld_short_description);
          setLongDescription(obj.data[0].fld_long_description);
          setMarketedBy(obj.data[0].fld_marketedby);
          setDataLoading(false);
          setselectedImage(obj.data[0].VariantImage?.split("#")[0]);
          setSubcategoryName(obj.data[0].fld_subcategoryname);
          setSubcategoryid(obj.data[0].fld_subcategoryid);
          setCategoryName(obj.data[0].fld_categoryname);
          setVerticalName(obj.data[0].fld_verticlename);
          setItemName(obj.data[0].fld_itemname);
          setVerticalid(obj.data[0].fld_verticleid);
          setSoldby(obj.data[0].soldby);
          setSKU(obj.data[0].fld_sku);
          setShippingCharge(obj.data[0].fld_shipping_amount);
          setVariantAttributeValue(obj.data[0].VariantAttributeValue);
          setItemsInCart(obj.data[0].qty_in_cart);
          setCartLineId(obj.data[0].cart_line_id);
          PostApiCall.postRequest(
            {
              categoryid: parseInt(obj.data[0].fld_categoryid),
              stateid: 0,
            },
            "GetBestSellingProduct"
          ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj1) => {
              if (results.status === 200 || results.status === 201) {
                //setAttributeDropdowndata(obj1.data);
                setAddToBagDisable(false);
                setDataLoading(false);
              }
            })
          );
          PostApiCall.postRequest(
            {
              variantid: parseInt(obj.data[0].fld_variantid),
            },
            "GetVariantDetailsById"
          ).then((results) =>
            results.json().then((obj1) => {
              if (results.status === 200 || results.status === 201) {
                setAttributeDropdownCount(obj1.data);
                PostApiCall.postRequest(
                  {
                    id: parseInt(obj.data[0].fld_variantid),
                    sfor: "VariantId",
                  },
                  "GetCategoryAttributeValue"
                ).then((results2) =>
                  // const objs = JSON.parse(result._bodyText)
                  results2.json().then((obj2) => {
                    // ;
                    if (results2.status == 200 || results2.status == 201) {
                      setAttributeDropdowndata(obj2.data);
                      setDataLoading(false);
                    }
                  })
                );
              }
            })
          );
          setAddToBagDisable(false);
        }
      })
    );

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function getExtension(filename) {
    return filename?.split(".").pop();
  }
  const largeImage = {
    src: String,
    srcSet: String,
    sizes: String,
    width: Number,
    height: Number,
    isFluidWidth: Boolean,
    alt: String,
    onLoad: Function,
    onError: Function,
  };
  return (
    <section className="py-lg-3 py-3">
      <GenerateAccess />
      <Container fluid>
        <Row>
          <Col lg={6}>
            {getExtension(VariantImage?.split("#")[0].toLowerCase()) ===
            "mp4" ? (
              <video
                src={VariantImage.split("#")[0]}
                autoPlay
                loop
                autoFocus
                autoCorrect="true"
                muted
                className="img-fluid w-100"
              />
            ) : (
              <img
                src={
                  SelectedMainImage != ""
                    ? SelectedMainImage
                    : VariantImage?.split("#")[0]
                }
                className="img-fluid w-100"
              ></img>
            )}
            <div className="mt-3">
              <MultiCarousel
                draggable={false}
                showDots={false}
                responsive={productThumbnail}
                ssr={true}
                infinite={false}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="mx-1"
              >
                {VariantImage?.split("#").map((image) => {
                  return (
                    <Col className="shawcase-thumbnail">
                      <div className="image-small">
                        {getExtension(image.toLowerCase()) === "mp4" ? (
                          <video
                            src={image}
                            autoPlay
                            loop
                            autoFocus
                            autoCorrect="true"
                            muted
                            className="img-fluid w-100"
                          />
                        ) : (
                          <img
                            src={image}
                            onClick={(e) => {
                              setSelectedMainImage(image);
                            }}
                            className="img-fluid w-100 cursor-pointer"
                          ></img>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </MultiCarousel>
            </div>
          </Col>
          <Col lg={6} className="mt-lg-0 mt-3">
            <h6 className="small">
              <Link
                to={`/categories/${VerticalName}/${Verticalid}`}
                className="text-decoration-none text-black-50"
              >
                {VerticalName}
              </Link>{" "}
              /{" "}
              <Link
                to={`/subcategories/${CategoryName}/${CategoryId}`}
                className="text-decoration-none text-black-50"
              >
                {CategoryName}
              </Link>{" "}
              /{" "}
              <Link
                to={`/products/${SubcategoryName}/${Subcategoryid}`}
                className="text-decoration-none text-black"
              >
                {SubcategoryName}
              </Link>
            </h6>
            <h3 className="mb-lg-2">
              {ItemName} - {VariantName}
            </h3>
            <Row>
              <Col lg={6} md={6} xs={6} className="mb-3">
                <div className="">
                  <p className="mb-0 small text-black-50">Brand </p>{" "}
                  <p className="mb-1">{Brandname}</p>
                </div>
              </Col>
              <Col lg={6} md={6} xs={6} className="mb-3">
                <div className="">
                  <p className="mb-0 small text-black-50">SKU </p>{" "}
                  <p className="mb-1">{SKU}</p>
                </div>
              </Col>
              <Col lg={6} md={6} xs={6} className="mb-3">
                <div className="">
                  <p className="mb-0 small text-black-50">Seller </p>{" "}
                  <p className="mb-1">{Soldby}</p>
                </div>
              </Col>
              <Col lg={6} md={6} xs={6} className="mb-3">
                <div className="">
                  <p className="mb-0 small text-black-50">Country of origin </p>{" "}
                  <p className="mb-1">{CountryOfOrigin}</p>
                </div>
              </Col>
            </Row>
            <h3
              className="fw-bold d-flex align-items-end gap-2 mb-lg-2"
              style={{ marginLeft: "-5px" }}
            >
              <span>
                <BiRupee className="rupee-icon" />
                {DiscountPrice}
              </span>
              <span className="text-success extra-small mb-1">
                Inclusive of all taxes
              </span>
            </h3>
            {DiscountPrice != MRP ? (
              <h5 className="d-flex align-items-center gap-3 mb-lg-2">
                <span className="mrp-price">
                  <BiRupee className="rupee-icon" />
                  {MRP}
                </span>
                <span className="fw-bold text-success">{SavePer} OFF</span>
              </h5>
            ) : (
              ""
            )}

            <hr />
            <div>
              <h6 className="fw-bold">Select Variant</h6>
              {SelectedVariant.variant_list !== null ? (
                <Row>
                  {SelectedVariant != null && SelectedVariant != ""
                    ? SelectedVariant.variant_list !== null
                      ? SelectedVariant.variant_list
                          .split("#")
                          .map((carddata) => (
                            <div className="variant-card-wrapper">
                              <Card className="variant-card cursor-pointer">
                                <Card.Body className="p-2">
                                  <div
                                    onClick={() => {
                                      getproductDetails(
                                        carddata
                                          .split("#")[0]
                                          .split("$")[1]
                                          .split(",")[1]
                                      );
                                    }}
                                  >
                                    <div className="text-center mb-3">
                                      {getExtension(
                                        carddata
                                          .split("$")[1]
                                          .split(",")[7]
                                          .split("~")[0]
                                          .toLowerCase()
                                      ) === "mp4" ? (
                                        <video
                                          src={
                                            carddata
                                              .split("$")[1]
                                              .split(",")[7]
                                              .split("~")[0]
                                          }
                                          autoPlay
                                          loop
                                          autoFocus
                                          autoCorrect="true"
                                          muted
                                          className="img-fluid w-100"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            carddata
                                              .split("$")[1]
                                              .split(",")[7]
                                              .split("~")[0]
                                          }
                                          className="img-fluid variant-thumb"
                                        />
                                      )}
                                    </div>
                                    <div className="text-center">
                                      {carddata
                                        .split("$")[1]
                                        .split(",")[9]
                                        .split("^")
                                        .map((attribute) => (
                                          <>
                                            <span className="fw-bold">
                                              {attribute.split("%")[0]}:{" "}
                                            </span>
                                            <span className="fw-bold">
                                              {attribute.split("%")[1]}
                                            </span>{" "}
                                            <br />
                                          </>
                                        ))}
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          ))
                      : ""
                    : ""}
                </Row>
              ) : (
                ""
              )}
            </div>
            {/* <Button className="btn btn-theme btn-lg d-flex align-items-center justify-content-between gap-2">
              <BiCart className="cart-btn-icon" /> Add to Cart
            </Button> */}
            <hr className="my-lg-4 my-4" />
            <Row className="justify-content-lg-start justify-content-center">
              {Stock !== "Out Of Stock" ? (
                ItemsInCart > 0 ? (
                  <AddToCartQty
                    itemid={VariantId}
                    quantity={ItemsInCart}
                    cartlineid={CartLineId}
                  />
                ) : (
                  <Col lg={3} xs={8}>
                    <AddToCard1 itemid={VariantId} />
                  </Col>
                )
              ) : (
                <Col lg={3} xs={12}>
                  {/* <h6 className="fw-bold out-of-stock-btn text-danger mb-0">Out of Stock</h6> */}
                  <button
                    type="button"
                    disabled
                    class="fw-bold btn w-100 out-of-stock-btn"
                  >
                    Out of Stock
                  </button>
                </Col>
              )}
            </Row>
            <hr className="my-lg-4 my-4" />
            <Row className="product-details">
              <Col lg={12}>
                <Tabs
                  defaultActiveKey="classification"
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="classification" title="Classification">
                    <div>
                      <table>
                        {AttributeDropdowndata.map((data) => {
                          return (
                            <tr>
                              <td className="bold-td-left">
                                {data.fld_attributename}
                              </td>
                              <td>:</td>
                              <td>{data.label}</td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </Tab>
                  <Tab eventKey="home" title="Product Details">
                    {LongDescription != null ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: LongDescription }}
                      ></div>
                    ) : (
                      <div className="no-data">
                        <p>No product details are available.</p>
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey="contact" title="Reviews">
                    {Reviews != null ? (
                      <div dangerouslySetInnerHTML={{ __html: Reviews }}></div>
                    ) : (
                      <div className="no-data">
                        <p>No reviews available from customers.</p>
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ProductDetail;
