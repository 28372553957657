import { AES } from "crypto-js";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToWords } from "to-words";
import PostApiCall from "../../Api/postApi";
import Payment_Success from "../../assets/images/Payment_successfull.png";
import { store } from "../../context/store";
import GetApiCall from "../../Api/getApi";

const PaymentSuccess = () => {
  let params = useParams();
  let navigate = useNavigate();
  let encryptdOrderId = [];
  const [mainOrder, setMainOrder] = useState([]);
  const { Cart, setCart } = useContext(store);
  const [prodData, setProdData] = useState([]);
  const { ProductCart, setProductCart } = useContext(store);
  const [amountInWords, setAmountInWords] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const { setcart, clientData } = useContext(store);
  const { txnid } = useParams();
  console.log(ProductCart)
  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "#0f3d74",
    });

    const login = localStorage.getItem("CustomerData");
    const logindetails = JSON.parse(login);
    var orderid = 0;
    if (localStorage.getItem("OrderDetails") !== null) {
      var Order = localStorage.getItem("OrderDetails");
      var Orderdetails = JSON.parse(Order);
      orderid = Orderdetails[0].order_id;
    }

    Notiflix.Loading.circle();

    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          const mobileno = obj1.data[0].mobile;
          PostApiCall.postRequest(
            {
              whereClause: `where  fld_payment_trx_num='${txnid}'`,
            },
            "GetWebsiteOrderDetails"
          ).then((results1) =>
            results1.json().then((obj) => {
              if (results1.status == 200 || results1.status == 201) {
                PostApiCall.postRequest(
                  {
                    trxid: txnid,
                    orderid: obj.data[0]?.fld_order_id,
                    //   orderid: "1",
                  },
                  "GetCustomerOrderForm"
                ).then((results) =>
                  results.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                      console.log(obj1.data);

                      //  Items List
                      let items = [];
                      let formattedAddress = "";
                      let customerordernumber = "";

                      items = obj1.data.map((item) => ({
                        name: item.item_name,
                        variant: item.variant_name,
                        quantity: item.fld_quantity,
                        price: item.fld_selling_price,
                        image: item.variant_image.replace(/ /g, "%20"),
                      }));

                      // For client shipping address according last index
                      const lastIndex = obj1.data.length - 1;
                      const customershippingplace =
                        obj1.data[lastIndex]?.fld_shipping_address_all;

                      const formattedAddresstest = customershippingplace
                        ?.replace(/<br\s*\/?>/gi, "\n")
                        ?.replace(/<\/?span[^>]*>|<\/?b>/g, "")
                        ?.replace(/<[^>]+>/g, "");

                      formattedAddress = formattedAddresstest?.replace(
                        /\n/g,
                        "<br />"
                      );

                      customerordernumber =
                        obj1.data[lastIndex]?.fld_orderNumber;

                      if (obj1.data.length > 0) {
                        const paymentMode = obj.data[0]?.Payment_Mode;
                        const currentOrderId = obj.data[0]?.fld_order_id;
                        setOrderId(obj.data[0]?.fld_order_id);
                        setMainOrder(obj.data);
                        SendMailers(
                          obj.data[0]?.Total_Inclusive_Tax,
                          paymentMode,
                          currentOrderId,
                          mobileno,
                          items,
                          formattedAddress,
                          customerordernumber
                        ); // get the item amount and send it to the mailer
                      }
                    }
                  })
                );
                Notiflix.Loading.remove();
              }
            })
          );
        }
      })
    );
  }, []);

  // send payment success mail
  const SendMailers = (
    amount,
    selectedPaymentMode,
    currentOrderId,
    mobileno,
    productsItems,
    formattedAddress,
    customerordernumber
  ) => {
    Notiflix.Loading.dots();

    var Order = localStorage.getItem("OrderDetails");
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var orderid = 0;
    if (localStorage.getItem("OrderDetails") !== null) {
      var Order = localStorage.getItem("OrderDetails");
      var Orderdetails = JSON.parse(Order);
      orderid = Orderdetails[0].order_id;
    }

    PostApiCall.postRequest(
      {
        ordernumber: txnid,
        customername: logindetails[0].fld_name,
        customeremail: logindetails[0].fld_email,
        amount: amount,
        mobile: mobileno,
        orderid: currentOrderId,
        paymentmode: selectedPaymentMode,

        // Add new details for mailer
        products: productsItems,
        shippingaddress: formattedAddress,
        customerordernumber: customerordernumber,
      },
      "CustomerOrderSuccessMailer"
    ).then((results2) =>
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          // console.log('Success')
          Notiflix.Loading.remove();
        }
      })
    );
    PostApiCall.postRequest(
      {
        cartID: ProductCart[0]?.
          fld_cart_id,
        // cartID: orderId,
      },
      "PurgeCart"
    ).then((result) =>
      result.json().then((obj3) => {
        if (result.status == 200 || result.status == 201) {
          setCart(0);
          setProductCart([])
          Notiflix.Loading.remove();

        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Not able to process data");
        }
      })
    );
  };

  const id = params.txnid;
  const secretKey = "mel#4321!";
  const encryptedID = AES.encrypt(
    JSON.stringify(id.toString()),
    secretKey
  ).toString();
  const urlSafeBase64 = encryptedID
    .replace(/\//g, "-")
    .toString()
    .replace("?", "bechofy");
  return (
    <div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div className="mt-3 text-end">
              <button
                className="btn btn-theme change-addressButton"
                onClick={() => {
                  navigate(`/view-invoice-form/${urlSafeBase64}`);
                }}
              >
                view order
              </button>
              <button
                // className="btn btn-theme change-addressButton mx-2"
                className="btn shop-btn change-addressButton mx-2"
                onClick={() => {
                  navigate("/");
                }}
              >
                Continue Shopping
              </button>
            </div>
            <div className="d-flex justify-content-center">
              <img
                src={Payment_Success}
                alt="payment success"
                className="w-50"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentSuccess;
