import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import PostApiCall from "../../Api/postApi";
import { store } from "../../context/store";

function AddToCartQty(props) {
  const itemid = props.itemid;
  const cartlineid = props.cartlineid;
  const { showLoginPopupWebsite, setLoginPopupWebsite } = useContext(store);
  const { ProductCart, setProductCart } = useContext(store);
  const { setCartItemBeforeLogin } = useContext(store);
  const { featuredProducts, setFeaturedProducts } = useContext(store);
  const { newArrivals, setNewArrivals } = useContext(store);
  const { bestSeller, setBestSeller } = useContext(store);
  const { Cart, setCart } = useContext(store);
  const { loginData, setloginData } = useContext(store);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    const cart = ProductCart.find((item) => item.fld_variantid === itemid);
    if (cart) {
      setQuantity(cart.fld_quantity);
    }
  }, [ProductCart, itemid]);
  const GetCartdetails = () => {
    Notiflix.Loading.dots("");
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    var orderid = 0;
    if (localStorage.getItem("OrderDetails") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    if (localStorage.getItem("CustomerData") === null) {
      setLoginPopupWebsite(true);
    } else {
      var login = localStorage.getItem("CustomerData");
      var logindetails = JSON.parse(login);
      if (localStorage.getItem("OrderDetails") !== null) {
        var Order = localStorage.getItem("OrderDetails");
        var Orderdetails = JSON.parse(Order);
        PostApiCall.postRequest(
          {
            orderid:
              localStorage.getItem("OrderDetails") == null
                ? null
                : Orderdetails[0].order_id,
            id:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
          },
          "GetCartWebsite"
        )
          .then((res) => res.json())
          .then((res1) => {
            // console.log(ProductCart)
            if (res1.data) {
              setProductCart(res1.data);
              setCart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
              Notiflix.Loading.remove();
            }
          });
      }
    }

    // PostApiCall.postRequest({
    //   customerid:
    //     localStorage.getItem("CustomerData") ==
    //       null
    //       ? null
    //       : logindetails[0].fld_customerid,
    //   categoryid: null,
    //   productid: null,
    //   variantid: null,
    //   sectionname: "Featured Products",
    //   orderid: orderid,
    //   itemchoice: "Random"
    // },
    //   "GetWebsiteHomePageItems"
    // ).then((results) => {
    //   results.json()?.then((obj) => {
    //     if (results.status == 200 || results.status == 201) {
    //       // ;
    //       setFeaturedProducts(obj.data);
    //     }
    //   });
    // });
    // PostApiCall.postRequest({
    //   customerid:
    //     localStorage.getItem("CustomerData") ==
    //       null
    //       ? null
    //       : logindetails[0].fld_customerid,
    //   categoryid: null,
    //   productid: null,
    //   variantid: null,
    //   sectionname: "New Arrivals",
    //   orderid: orderid,
    //   itemchoice: "Random"
    // },
    //   "GetWebsiteHomePageItems"
    // ).then((results) => {
    //   results.json()?.then((obj) => {
    //     if (results.status == 200 || results.status == 201) {
    //       // ;
    //       setNewArrivals(obj.data);
    //     }
    //   });
    // });
    // PostApiCall.postRequest({
    //   customerid:
    //     localStorage.getItem("CustomerData") ==
    //       null
    //       ? null
    //       : logindetails[0].fld_customerid,
    //   categoryid: null,
    //   productid: null,
    //   variantid: null,
    //   sectionname: "Best Seller",
    //   orderid: orderid,
    //   itemchoice: "Random"
    // },
    //   "GetWebsiteHomePageItems"
    // ).then((results) => {
    //   results.json()?.then((obj) => {
    //     if (results.status == 200 || results.status == 201) {
    //       // ;
    //       setBestSeller(obj.data);
    //     }
    //   });
    // });
  };
  const deletecart = (props) => {
    Notiflix.Loading.dots("");
    var Order = localStorage.getItem("OrderDetails");
    var login = localStorage.getItem("CustomerData");
    var Orderdetails = JSON.parse(Order);
    var logindetails = JSON.parse(login);
    var orderid = 0;
    if (localStorage.getItem("OrderDetails") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    PostApiCall.postRequest(
      {
        cartID: orderid,
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        cartlineid: cartlineid,
        stateid: 0,
      },
      "DeleteCart"
    ).then((result) =>
      result.json().then((obj3) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Notify.success("Product is successfully deleted.");
          Notiflix.Loading.remove();
          GetCartdetails();
          if (ProductCart.length == 1) {
            localStorage.removeItem("OrderDetails");
          }
          GetCartdetails();
        } else {
          Notiflix.Notify.failure(obj3.data);
          Notiflix.Loading.remove();
        }
      })
    );
  };

  const addtocart = (props) => {
    Notiflix.Loading.dots("");

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    if (logindetails != null) {
      setloginData(logindetails);
    }
    if (logindetails === null) {
      setLoginPopupWebsite(true);
      setCartItemBeforeLogin(itemid);
    } else {
      // ===========with Login=========
      PostApiCall.postRequest(
        {
          orderdate: moment().format("lll"),
          itemdetails: `
                     [
                     {
                         "Item":${itemid},
                         "Quantity":${props}
                     }
                     ]
                     `,
          status: "INCART",
          customerid:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_customerid,
          createdon: moment().format("lll"),
          updatedon: moment().format("lll"),
          orderid:
            localStorage.getItem("OrderDetails") == null
              ? 0
              : Orderdetails[0].order_id,
          updatedby:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_userid,
        },
        "AddToCartWebsite"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            Notiflix.Notify.success("Product added to Cart.");
            Notiflix.Loading.remove();
            if (localStorage.getItem("OrderDetails") == null) {
              {
                // ;
              }
              localStorage.setItem("OrderDetails", JSON.stringify(obj.data));
              GetCartdetails();
            } else {
              GetCartdetails();
            }
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(obj.data);
          }
        })
      );
    }
  };
  return (
    <>
      <div class="_quantity-wrapper qauntity-full-width">
        <div class="d-flex justify-content-between col-lg-4 quantity-border quntity-full">
          <h6 class="price price--end m-0 pricee">
            <div className="_quantity-wrapper quantityy">
              <label className="visually-hidden" htmlFor="Quantity-1">
                Quantity
              </label>
              <quantity-input class="quantity-checkout" type="text">
                <button
                  class="quantity__button test no-js-hidden listing-page"
                  name="minus"
                  type="button"
                  onClick={() => {
                    addtocart(-1);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    class="icon icon-minus"
                    fill="none"
                    viewBox="0 0 10 2"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
                <input
                  className="quantity__input"
                  type="text"
                  value={quantity}
                  min="0"
                  id="Quantity-1"
                  data-index="1"
                />
                <button
                  className="quantity__button test no-js-hidden listing-page"
                  name="plus"
                  type="button"
                  onClick={() => {
                    addtocart(1);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    class="icon icon-plus"
                    fill="none"
                    viewBox="0 0 10 10"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
              </quantity-input>
              <div className=" checkout-delete ms-lg-0 ms-2">
                <cart-remove-button
                  id="Remove-1"
                  data-index="1"
                  className="Remove-1"
                  onClick={deletecart}
                >
                  <a style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      className="icon icon-remove"
                      // onClick={addtocart}
                      style={{
                        height: "20px",
                        width: "20px",
                      }}
                    >
                      <path
                        d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </a>
                </cart-remove-button>
              </div>
            </div>
          </h6>
        </div>
      </div>
    </>
  );
}

export default AddToCartQty;
