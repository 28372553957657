import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import PostApiCall from "../../Api/postApi";
import { store } from "../../context/store";
import { BiSolidUserPlus } from "react-icons/bi";
import GoogleLoginComponent from "../GoogleLogin/GoogleLogin";
function GuestLogin(props) {
	// const history = useHistory();
	let [data, setData] = useState([]);
	let [fullName, setFullName] = useState("");
	let [middleName, setMiddleName] = useState("");
	const { setCart, setProductCart, setcartamount } = useContext(store);
	const { cartItemBeforeLogin, setCartItemBeforeLogin } = useContext(store);
	let [lastName, setLastName] = useState("");
	let [email, setEmail] = useState("");
	let [confirmEmail, setConfirmEmail] = useState("");
	let [mobile, setmobile] = useState("");
	let [confirmMobile, setConfirmMobile] = useState("");
	let [password, setpassword] = useState("");
	let [dob, setDob] = useState("");
	let [passwordeye, setpasswordeye] = useState(false);
	let [confirmpasswordeye, setconfirmpasswordeye] = useState(false);
	const [gender, setGender] = React.useState("");
	const { open, setOpen } = useContext(store);
	const { openRegistration, setopenRegistration, openGuestLogin, setOpenGuestLogin } = useContext(store);
	const { showLoginPopupWebsite, setLoginPopupWebsite } = useContext(store);
	const handleCloseRegistrationPopup = () => {
		setLoginPopupWebsite(false)
		setOpenGuestLogin(false)
		setopenRegistration(false)
	}

	const handleChange = (field) => {
		return (e) => {
			clearErrors(field);
			setValue(field, e.target.value);
		};
	};

	const handlepasswordclick = () => {
		setpasswordeye(!passwordeye);
	};

	const {
		register,
		handleSubmit,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		Notiflix.Loading.dots();
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		PostApiCall.postRequest(
			{
				name: data.fullName,
				mobile: data.mobile,
				email: data.email,
				cartid:
					localStorage?.getItem("OrderData") == null
						? null
						: Orderdetails[0].order_id,
				signupguest: "Continue",
			},
			"AddGuest"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					Notiflix.Notify.success("Login Successfull");
					localStorage.setItem("CustomerData", JSON.stringify(obj.data));
					if (cartItemBeforeLogin !== null) {
						PostApiCall.postRequest(
							{
								orderdate: moment().format("lll"),
								itemdetails: `[{"Item":${cartItemBeforeLogin},"Quantity":${1}}]`,
								status: "INCART",
								customerid:
									localStorage.getItem("CustomerData") == null
										? null
										: obj.data[0].fld_customerid,
								createdon: moment().format("lll"),
								updatedon: moment().format("lll"),
								orderid:
									localStorage.getItem("OrderDetails") == null ||
										localStorage.getItem("OrderDetails") == ""
										? 0
										: obj.data[0].order_id,
								updatedby:
									localStorage.getItem("CustomerData") == null
										? null
										: obj.data[0].fld_customerid,
							},
							"AddToCartWebsite"
						).then((result) =>
							result.json().then((obj) => {
								if (result.status == 200 || result.status == 201) {
									Notiflix.Loading.remove();
									//
									if (
										localStorage.getItem("OrderDetails") == null ||
										localStorage.getItem("OrderDetails") == ""
									) {
										//
										localStorage.setItem(
											"OrderDetails",
											JSON.stringify(obj.data)
										);
										setopenRegistration(false);
										setOpenGuestLogin(false);
										setLoginPopupWebsite(false)
										getCartdetails();
										setCartItemBeforeLogin(null);
									}
									// getCartdetails1();
								} else {
									Notiflix.Notify.failure(obj.data);
								}
							})
						);
					} else {
						Notiflix.Loading.remove();
						setopenRegistration(false)
						setOpenGuestLogin(false)
						setLoginPopupWebsite(false)
						getCartdetails();

					}
					// onLogin(data);
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure(obj.data);
				}
			})
		);
	};
	const getCartdetails = () => {
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderDetails");
		var Orderdetails = JSON.parse(Order);

		PostApiCall.postRequest(
			{
				orderid:
					localStorage.getItem("OrderDetails") == null
						? null
						: Orderdetails[0].order_id,
				id:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
			},
			"GetCartWebsite"
		)
			.then((res) => res.json())
			.then((res1) => {
				if (res1.data) {
					setProductCart(res1.data);
					setCart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
					setcartamount(res1.data[0]?.fld_cart_amount_preview);
				}
			});
	};
	const onLogin = (data) => {
		PostApiCall.postRequest(
			{
				email: data.email,
				password: data.password,
				action: "Login",
				actiondate: moment().format("lll"),
			},
			"AddwebsiteLogin"
		).then((results) =>
			results.json().then((obj) => {
				if (
					(results.status == 200 || results.status == 201) &&
					cartItemBeforeLogin !== null
				) {
					localStorage.setItem("CustomerData", JSON.stringify(obj.data));
					setLoginPopupWebsite(false);
					// ===========with Login=========
					PostApiCall.postRequest(
						{
							orderdate: moment().format("lll"),
							itemdetails: `[{"Item":${cartItemBeforeLogin},"Quantity":${1}}]`,
							status: "INCART",
							customerid:
								localStorage.getItem("CustomerData") == null
									? null
									: obj.data[0].fld_customerid,
							createdon: moment().format("lll"),
							updatedon: moment().format("lll"),
							orderid:
								localStorage.getItem("OrderDetails") == null ||
									localStorage.getItem("OrderDetails") == ""
									? 0
									: obj.data[0].order_id,
							updatedby:
								localStorage.getItem("CustomerData") == null
									? null
									: obj.data[0].fld_customerid,
						},
						"AddToCartWebsite"
					).then((result) =>
						result.json().then((obj) => {
							if (result.status == 200 || result.status == 201) {
								Notiflix.Loading.remove();
								//
								if (
									localStorage.getItem("OrderDetails") == null ||
									localStorage.getItem("OrderDetails") == ""
								) {
									//
									localStorage.setItem(
										"OrderDetails",
										JSON.stringify(obj.data)
									);
									setopenRegistration(false);
									getCartdetails();
									setCartItemBeforeLogin(null);
								}
								// getCartdetails1();
							} else {
								Notiflix.Notify.failure(obj.data);
							}
						})
					);
					// onPostMoveCart(obj);
				} else if (results.status == 200 || results.status == 201) {
					Notiflix.Loading.remove();
					localStorage.setItem("CustomerData", JSON.stringify(obj.data));
					// (window.location.href = "/");
					setopenRegistration(false);

					if (localStorage.getItem("currentPage") != null) {
						localStorage.getItem("currentPage") == "Cart"
							? (window.location.href = "/checkout")
							: (window.location.href = "/");
					} else {
						localStorage.getItem("ReviewPage") == "ReviewPage"
							? this.props.history.goBack()
							: (window.location.href = "/");
					}
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure(obj.data);
				}
			})
		);
	};
	return (
		<Modal show={openGuestLogin} onHide={handleCloseRegistrationPopup}>
			<Modal.Header closeButton className="border-0 mb-0 pb-0"></Modal.Header>
			<Modal.Body className="mt-0 pt-0">
				<div className="login-box py-0 my-0">
					<div className="section-title mb-0 pb-3 mt-0">
						<div className="login-modal-icon">
							<BiSolidUserPlus />
						</div>
						<h5 className="text-center mb-0 pb-0 my-1 fw-bold">
							Seamless Experience with Guest Login!
						</h5>
						<p className="text-center mb-0 mt-2 mb-0 fs-6">
							Start Shopping Instantly!
						</p>
					</div>
				</div>
				{/* Guest Login Modal */}

				<>

					{/* <p
						style={{
							textAlign: "center",
							marginTop: "-5px",
							marginBottom: "30px",
						}}
					>
					</p> */}

					<form onSubmit={handleSubmit(onSubmit)} >
						<div className="text-field mt-0 pt-0">
							<div className="row ">
								<div className="col-md-12 col-12">
									<FloatingLabel
										controlId="floatingInput"
										label="Full Name"
										className="mb-3"
									>
										<Form.Control
											fullWidth
											placeholder="Full Name"
											label={
												fullName.fld_field_name
													? fullName.fld_field_name
													: "Full Name"
											}
											variant="outlined"
											className="mb-3"
											id="fullName"
											{...register("fullName", {
												required: "First name is required",
											})}
											onChange={handleChange("fullName")}
										/>
										{errors.fullName && (
											<p className="text-danger">{errors.fullName.message}</p>
										)}
									</FloatingLabel>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 col-12">
								<FloatingLabel
									controlId="floatingInput"
									label="Email Address"
									className="mb-3"
								>
									<Form.Control
										placeholder="Email"
										fullWidth
										label={
											email.fld_field_name ? email.fld_field_name : "Email*"
										}
										className="text-field-input"
										id="email"
										{...register("email", {
											required: "Email ID is required",
											pattern: {
												value:
													/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
												message: "Invalid email format",
											},
										})}
										onChange={handleChange("email")}
									/>
									{errors.email && (
										<p className="text-danger">{errors.email.message}</p>
									)}
								</FloatingLabel>
							</div>
							<div className="col-md-12 col-12">
								<FloatingLabel
									controlId="floatingInput"
									label={mobile.fld_field_name ? mobile.fld_field_name : "Mobile Number"}
									className="mb-3"
								>
									<Form.Control
										fullWidth
										placeholder="Mobile Number"
										id="mobile"
										className="text-field-input mg-textfield capital"
										style={{ width: "100%" }}
										{...register("mobile", {
											required: mobile.fld_error_message,
											minLength: {
												value: 10,
												message: "Enter minimum 10-digit number",
											},
											pattern: {
												value: /^(0|[1-9]\d*)(\.\d+)?$/,
												message: "Enter valid mobile number",
											},
										})}
										inputProps={{ maxLength: 10 }}
									/>
									{errors.mobile && (
										<p className="text-danger">{errors.mobile.message}</p>
									)}
								</FloatingLabel>

							</div>
						</div>

						<Button variant="contained" className="shop-btn mt-2 mb-3 w-100" type="submit">
							Continue
						</Button>
					</form>
					<div
						className="newTowebsite-register"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<p>Already an user?</p>
						<p
							style={{
								marginLeft: "5px",
								cursor: "pointer",
								color: "#221f1f",
								fontWeight: "bold",
							}}
							onClick={() => {
								setopenRegistration(false);
								setLoginPopupWebsite(true);
								setOpenGuestLogin(false);
							}}
						>
							Login
						</p>
					</div>
					<GoogleLoginComponent />
				</>

			</Modal.Body>
		</Modal>
	);
}
export default GuestLogin;
