import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../src/components/Footer/Footer";
import MyAccount from "../src/components/MyAccount/ViewCustomer";
import Navbar from "../src/components/Navbar/Navbar";
import About from "../src/pages/About";
import ContactUs from "../src/pages/ContactUs";
import Home from "./components/Dashboard/Home/Home";
import ProductDetail from "../src/pages/Products";
import "./App.css";
import Provider from "./context/store";
import Checkout from "./pages/Checkout/Checkout";
import CheckoutOrderSummary from "./pages/Checkout/Shipping";
import PaymentProcess from "./pages/Payment/PaymentProcess";
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import OrderForm from "./components/MyAccount/View-order";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ReturnPolicy from "./pages/ReturnPolicy";
import FAQ from "./pages/FAQ";
import BlogDetail from "./components/BlogSection/BlogDetail";
import Category from "./components/Category/Category";
import SubCategorylist from "./components/SubCategory/SubCategory";
import Productlist from "./pages/ProductList";
import PaymentFail from "./pages/Payment/PaymentFail";
import ProductDetailNew from "./pages/Products1";
import PageNotFound from "./components/PageNotFound/PageNotFound";

function App() {
  return (
    <Provider>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route
            exact
            path="/product-detail-old/:variantid"
            element={<ProductDetail />}
          />
          <Route
            exact
            path="/product-detail/:variantid"
            element={<ProductDetailNew />}
          />
          <Route exact path="/profile" element={<MyAccount />} />
          <Route exact path="/testimonials" element={<ContactUs />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route
            exact
            path="/checkoutsummary"
            element={<CheckoutOrderSummary />}
          />
          <Route exact path="/blog" element={<ContactUs />} />
          <Route exact path="/blogdetails/:id" element={<BlogDetail />}></Route>
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route
            exact
            path={`/paymentprocess/:txnid`}
            element={<PaymentProcess />}
          ></Route>
          <Route
            exact
            path={`/paymentsuccess/:txnid`}
            element={<PaymentSuccess />}
          />
          <Route exact path={`/paymentfail`} element={<PaymentFail />} />
          <Route
            exact
            path={`/view-invoice-form/:txnid`}
            element={<OrderForm />}
          />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route exact path="/return-policy" element={<ReturnPolicy />} />
          <Route exact path="/frequently-asked-questions" element={<FAQ />} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ct/:verticalname`}
            element={<Category />}
          />
          {/* <Route
            exact
            path="/categories/:verticalname/:verticalid"
            element={<Category />}
          /> */}
          <Route
            exact
            path={`/subcategories/:subcategoriesname/:categoriesid`}
            element={<SubCategorylist />}
          />
          {/* <Route exact path="/products/:subcategoryname/:subcategoryid" element={<Productlist />} /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pct/:categoryname`}
            element={<Productlist />}
          />
          {/* <Route
            exact
            path={`${process.env.PUBLIC_URL}shop`}
            element={<Productlist />}
          /> */}
          {/* <Route
            exact
            path="/products/:categoryname/:categoryid"
            element={<Productlist />}
          /> */}
          <Route exact path="/shop" element={<Productlist />} />
          <Route exact path="/shop/:categoryname" element={<Productlist />} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/p/:productname`}
            // path="/product-detail-new"
            element={<ProductDetailNew />}
          />
          <Route path="*" element={<PageNotFound />} />
          {/* <ProtectedRoutes
            exact
            path={`${process.env.PUBLIC_URL}/view-invoice-form/:orderid`}
            component={ViewInvoice}
            isAuth={localStorage.getItem("CustomerData") == null ? false : true}
          ></ProtectedRoutes> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
