import { Skeleton } from "antd";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card2 from "../../ProductCards/Card2";
import "./ShopByCategory.css";

function ShopByCategory(props) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  };

  const isMobile = window.innerWidth < 1024;

  return (
    <>
      <section className="mt-md-5 mt-4">
        <div className="container-fluid container-md">
          {props.productdata.length <= 0 ? (
            <div className="">
              <div className="d-flex justify-content-center">
                <Skeleton.Input active={true} size="large" />
              </div>
              <div className="d-flex justify-content-center my-2">
                <Skeleton.Input active={true} size="large" />
              </div>
              <div className="d-flex justify-content-center">
                <Skeleton.Input active={true} size="large" />
              </div>
              <div className="mx-2 mt-4">
                <Row>
                  <Col className="text-center bg-light ">
                    <Skeleton.Image
                      active={true}
                      className="img-fluid w-100 product-image-skeleton"
                    />
                    <Skeleton.Input
                      active={true}
                      className="mb-lg-2 mb-3 fw-bold"
                      block
                    />
                  </Col>
                  <Col className="text-center bg-light d-none d-md-block ">
                    <Skeleton.Image
                      active={true}
                      className="img-fluid w-100 product-image-skeleton"
                    />
                    <Skeleton.Input
                      active={true}
                      className="mb-lg-2 mb-3 fw-bold"
                      block
                    />
                  </Col>
                  <Col className="text-center bg-light d-none d-md-block ">
                    <Skeleton.Image
                      active={true}
                      className="img-fluid w-100 product-image-skeleton"
                    />
                    <Skeleton.Input
                      active={true}
                      className="mb-lg-2 mb-3 fw-bold"
                      block
                    />
                  </Col>
                  <Col className="text-center bg-light d-none d-md-block ">
                    <Skeleton.Image
                      active={true}
                      className="img-fluid w-100 product-image-skeleton"
                    />
                    <Skeleton.Input
                      active={true}
                      className="mb-lg-2 mb-3 fw-bold"
                      block
                    />
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <>
              <Row className="justify-content-center">
                <Col lg={10} className="text-center">
                  <h2 className="category-h2-content">{props.header1}</h2>
                  {/* <h3 className="category-h3-content wow animate__animated animate__fadeInUp">
                    {props.header2}
                  </h3> */}
                  <h4 className="category-h4-content wow animate__animated animate__fadeInUp">
                    {props.header3}
                  </h4>
                </Col>
              </Row>

              <div className="position-relative shopcategory-arrow mt-3">
                <Carousel
                  swipeable={isMobile}
                  draggable={false} // Set draggable to false to disable dragging
                  // showDots={false}
                  showDots={isMobile} // Show dots only in mobile view
                  responsive={responsive}
                  ssr={true}
                  slidesToSlide={1}
                  infinite={false}
                  containerClass="carousel-container"
                  className="pb-2 category-dot-tab common-border-radious home-shop-carousel"
                // autoPlay={isMobile}  // Enable autoplay for mobile view
                // autoPlaySpeed={3000} // Set the autoplay speed (in milliseconds)
                >
                  {props.productdata
                    .filter((data) => {
                      if (data.fld_status === "Active") {
                        return data;
                      }
                    })
                    .map((product) => (
                      <Card2
                        className="common-border-radious"
                        product={product}
                        path={`/ct/${product.fld_route}`}
                      // path={`/categories/${
                      //   product.fld_name == null
                      //     ? ""
                      //     : product.fld_name.replace(/\s/g, "-")
                      // }/${product.fld_verticleid}`}
                      />
                    ))}
                </Carousel>
              </div>
            </>
          )}

          <div className="row mt-3 mb-0 pb-0">
            <div className="col-12 text-center">
              <p className="pb-0 mb-0 category-p-content">
                Our exclusive hand block designs, are manufactured in limited
                quantities, that feature intricate motifs and patterns. Fine
                quality fabrics and the adept handiwork of our master artisans
                add a touch of luxury and sophistication to each product ,
                especially made for beautiful homes.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ShopByCategory;
