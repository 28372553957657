import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import GetApiCall from "../Api/getApi";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import "./Faq.css";
import { MdExpandMore } from "react-icons/md";
import { Skeleton } from "antd";
import GetSeoData from "../components/GetSeo/GetSeoData";

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [faq, setFaq] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetApiCall.getRequest("GetFAQS").then((results) => {
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setFaq(obj.data);
        }
      });
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <GetSeoData type="Faq" id={null} />
      <section>
        <HeroBanner />
        <div className="container-fluid container-md blank-section">
          {faq.length <= 0 ? (
            <Row className="justify-content-center">
              <Col lg={12} className="d-flex flex-column justify-content-center align-items-center">
                <Skeleton.Input
                  active={true}
                  className="mb-2 w-50 text-center"
                  block
                  size="small"
                />
                {/* <Skeleton.Input
                  active={true}
                  className="mb-3 w-25 text-center"
                  block
                  size="small"
                /> */}
              </Col>
              <Col lg={12}>
                <Skeleton.Input
                  active={true}
                  className="mb-3"
                  block
                  size="large"
                />
                <Skeleton.Input
                  active={true}
                  className="mb-3"
                  block
                  size="large"
                />
                <Skeleton.Input
                  active={true}
                  className="mb-3"
                  block
                  size="large"
                />
                <Skeleton.Input
                  active={true}
                  className="mb-3"
                  block
                  size="large"
                />
              </Col>
              <Col lg={12}>
                <Skeleton.Input
                  active={true}
                  className="mb-3"
                  block
                  size="large"
                />
                <Skeleton.Input
                  active={true}
                  className="mb-3"
                  block
                  size="large"
                />
                <Skeleton.Input
                  active={true}
                  className="mb-3"
                  block
                  size="large"
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="align-items-center justify-content-center mb-2">
                <Col lg={12} className="text-center px-lg-5 px-2">
                  {/* <h2 className="main-title wow animate__animated animate__fadeInUp text-capitalize">
                FAQ's
              </h2> */}
                  <p className="main-text wow animate__animated animate__fadeInUp">
                    Some Frequently Asked Questions related to the shopping
                    experience with us are answered below for ready reference.
                  </p>
                </Col>

                <Col lg={12} className="mb-5">
                  {faq.map((data, i) => {
                    return (
                      <>
                        <Accordion
                          expanded={expanded === i}
                          onChange={handleChange(i)}>
                          <AccordionSummary
                            expandIcon={<MdExpandMore />}
                          // aria-controls="panel4bh-content"
                          // id="panel4bh-header"
                          >
                            <Typography
                              className="fontacco"
                              sx={{ width: "80%", flexShrink: 0 }}>
                              {data.fld_question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="fontacco1">
                              {data.fld_answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    );
                  })}
                </Col>
              </Row>
            </>
          )}
        </div>
      </section>
    </div>
  );
}
